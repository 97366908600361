<template>
  <div class="home">
    <div class="search">
      <div class="content">
        <h1 v-html="'FINANCIAMIENTO'"></h1>
      </div>
    </div>
    <div class="section full color3">      <div>
        <div class="content">
          <p
            v-html="
              'Corporaci&oacute;n Puerto Cristal financia su labor a trav&eacute;s del pago de cuotas y aportes de sus socios, entradas al Museo de Sitio de Puerto Cristal y principalmente mediante la ejecuci&oacute;n de proyectos con financiamiento p&uacute;blico. A continuaci&oacute;n, se detalla el financimiento obtenido con aportes del Estado.'
            "
          ></p>
          <div class="item color1">
            <h3>Fondo de Fortalecimiento de Organizaciones Patrimoniales - FFOP</h3>
            <p><b><span>Fecha de Transferencia:</span></b> 20/09/2024</p>
            <p><b><span>Monto Transferido:</span></b> $ 28.450.312.- (Veintiocho millones cuatrocientos cincuenta mil trescientos doce pesos)</p>
            <p><b><span>Instituci&oacute;n Otorgante:</span></b> Servicio del Patrimonio Cultural (SERPAT)</p>
          </div>
          <div class="item color1">
            <h3>Fondo del Patrimonio - FONPAT</h3>
            <p><b><span>Fecha de Transferencia:</span></b> 27/05/2024</p>
            <p><b><span>Monto Transferido:</span></b> $ 15.000.000.- (Quince millones de pesos)</p>
            <p><b><span>Instituci&oacute;n Otorgante:</span></b> Servicio del Patrimonio Cultural (SERPAT)</p>
          </div>
          <div class="item color1">
            <h3>Fondo Nacional de Desarrollo Cultural y las Artes - FONDART</h3>
            <p><b><span>Fecha de Transferencia:</span></b> 27/05/2024</p>
            <p><b><span>Monto Transferido:</span></b> $ 13.600.000.- (Trece millones seiscientos mil pesos)</p>
            <p><b><span>Instituci&oacute;n Otorgante:</span></b> Subsecretaría de las Culturas y las Artes</p>
          </div>
        </div>
      </div>
    </div>

    <Loading text="cargando" />
    <Message />
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
  },
  data: function () {
    return {
      col: [
        {
          link: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fdocs%2FCONVENIO_97223.pdf?alt=media&token=91654e01-44f2-4fc3-bdb7-e4ee6e72cc1c",
          imagen:
            "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fdocs%2FCONVENIO_97223.jpg?alt=media&token=a0300e98-03ba-4806-8209-6e8fb4b07a97",
          title: "Convenio FFOP 2024",
        },
        {
          link: "/#",
          icon: "description",
          title: "Balance 2024 (proximamente)",
        },
        {
          link: "/#",
          icon: "description",
          title: "Estados Financieros 2024 (proximamente)",
        },
        {
          link: "/#",
          icon: "description",
          title: "Memoria Anual de Actividades - 2024 (proximamente)",
        },
      ],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations(["SET_LOADING"]),
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
@import "../assets/sass/buttons";
@import "../assets/sass/cards";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("../assets/media/proyectos.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    overflow: hidden;
    .video-container {
      position: absolute;
      width: 100%;
      height: 100%;
      video {
        min-width: 100%;
        min-height: 100%;

        /* Setting width & height to auto prevents the browser from stretching or squishing the video */
        width: auto;
        height: auto;

        /* Center the video */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .content {
      background-color: rgba($color: #ffb980, $alpha: 0.6);
      position: relative;
      bottom: 0%;
      padding: 2em;
      margin: auto 2em 2em 2em;
      h3 {
        font-size: 1.4em;
        color: $theme-d3;
        font-weight: 500;
      }
      h1 {
        font-size: 3em;
        font-weight: 700;
        color: $theme-d3;
        margin-block-end: 0;
        margin-block-start: 0;
      }
    }
  }
  .section {
    position: relative;
    padding: 2em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,
    &.full {
      height: 100%;
      div {
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.2em;
            font-weight: 300;
          }
          .item{
            display: block;
            width: 100%;
            height: fit-content !important;
            border-radius: 5px;
            padding: 0.5em 1em;
            margin-bottom: 5px;
            h3{
              font-weight: bold;

            }
            &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
          }
        }
        .image {
          border-radius: 1em;
          height: 100%;
          padding-top: auto;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("../assets/media/lineas_de_trabajo.png");
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 2em;
      &.split {
        grid-template-columns: 1.5fr 2.5fr;
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        grid-template-columns: 1.5fr 2.5fr;
      }
    }
  }
}
</style>
