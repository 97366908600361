<template>
  <div class="container">
    <h4 v-html="'Registrar Nuevo Objeto'"></h4>
    <DotStep :dotNumber="2" v-model="step" />

    <div v-if="step === 1">
      <p
        class="full-length"
        v-html="
          'Seleccione la colecci&oacute;n a la que pertenece el objeto e ingrese su nombre'
        "
      ></p>
      <label v-html="'Colecci&oacute;n'"></label>
      <select
        name="collection"
        id="collection"
        v-model="collection"
        ref="collection"
      >
        <option value="none" v-html="'Seleccione Colecci&oacute;n'"></option>
        <option v-for="item in collections" :key="item.id" :value="item.id">
          {{ item.name }}
        </option>
      </select>
      <div>
        <label>Nombre</label>
        <div class="autocomplete-container">
          <Autocomplete
            :list="glossary"
            alternativeText="Ingresar el nombre"
            v-on:get-result="setResult"
            :border="true"
            height="30px"
            margin="0px"
            :showInfo="true"
          />
        </div>
      </div>
      <div v-show="name !== nameResult.name && name !== ''">
        <p
          class="full-length note"
          v-html="
            '**El nombre del objeto no se encuentra registrado en la base de datos, ingrese su definici&oacute;n para continuar'
          "
        ></p>
        <label v-html="'Definici&oacute;n'"></label>
        <textarea
          name="definition"
          id="definition"
          rows="5"
          v-model="definition"
        ></textarea>
        <label v-html="'Fuente'"></label>
        <input type="text"
          v-model="source"
          id="source">
      </div>
      <button :disabled="!checkStep1" @click="step++" class="next-btn">
        <p>Continuar</p>
        <div>
          <i class="material-icons">arrow_forward_ios</i>
        </div>
      </button>
    </div>
    <div v-else>
      <p
        class="full-length"
        v-html="
          'Clasifique el objeto en su respectiva categor&iacute;a y subcategor&iacute;as'
        "
      ></p>
      <label v-html="'Categor&iacute;a'"></label>
      <CategorySelector v-on:selected-category="setParents" />
      <button :disabled="parents.length < 1" @click="step++" class="next-btn">
        <p>
          <button class="add-btn" @click="create" :disabled="btnDisabled">
            Agregar
          </button>
        </p>
      </button>
    </div>
    <!--<div v-else>
      <div class="image-upload">
        <img :src="filePreview" />
        <div class="input">
          <label>Imagen de Referencia</label>
          <input type="text" disabled v-model="mainImage" />
          <input class="file" type="file" ref="fileInput" accept="image/*" @change="onFilePicked" />
        </div>
        <button @click="onPickFile">
          <i class="material-icons">cloud_upload</i>
        </button>
      </div>
      <button class="add-btn" @click="create" :disabled="btnDisabled">Agregar</button>
    </div>-->
  </div>
</template>
<script>
import { mapGetters, mapMutations,mapActions } from "vuex";
import DotStep from "@/components/DotStep.vue";
import CategorySelector from "@/components/CategorySelector.vue";
import Autocomplete from "@/components/Autocomplete.vue";
export default {
  name: "Add-object",
  data: function () {
    return {
      step: 1,
      parents: [],
      name: "",
      nameResult: { id: "", name: "", definition: "" },
      definition: "",
      source:"",
      collection: "none",
      collectionList: [],
      newId: "",
      counter: -1,
      messageData: {},
    };
  },
  components: {
    DotStep,
    Autocomplete,
    CategorySelector,
  },
  computed: {
    
    ...mapGetters(["categories", "collections", "user", "getMessageAction"]),
    ...mapGetters("typologies", {
      glossary: "items",
    }),
    checkStep1() {
      if (this.collection !== "none") {
        if (this.name === this.nameResult.name && this.nameResult.id !== "") {
          return true;
        } else {
          if (
            this.definition !== "" &&
            this.definition !== undefined &&
            this.name !== ""
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },
    btnDisabled() {
      if (this.parents.length > 0 && this.checkStep1) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    getMessageAction(payload) {
      if (payload.component === "add") {
        if (payload.action === 1) {
          this.$route.push("item/" + this.newId + "/edit");
        }
        this.SET_MESSAGE_PARAMETERS({});
      }
    },
  },
  methods: {
    ...mapActions("typologies",{
      addTypology:"addItem",
    }),
    ...mapActions(["createNewObject"]),
    ...mapMutations([
      "SET_LOADING",
      "SET_LOADING_OPACITY",
      "SET_MESSAGE_PARAMETERS",
      "SET_CURRENT_OBJECT_ID",
      "ADD_ITEM",
    ]),
    setResult(payload) {
      if (payload.doc === null || payload.doc === undefined) {
        this.name = payload.text;
        this.nameResult = { id: "", name: "", definition: "" };
      } else {
        if (payload.text === payload.doc.name) {
          this.name = payload.doc.name;
          this.nameResult = payload.doc;
        } else {
          this.name = payload.text;
          this.nameResult = { id: "", name: "", definition: "" };
        }
      }
    },
    onPickFile: function () {
      this.$refs.fileInput.click();
    },
    onFilePicked: function (event) {
      const file = event.target.files;
      let fileName = file[0].name;
      if (fileName.lastIndexOf(".") <= 0) {
        return alert("Ingrese una imagen valida");
      }
      this.mainImage = fileName;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.filePreview = fileReader.result;
      });
      fileReader.readAsDataURL(file[0]);
      this.image = file[0];
    },
    setParents(payload) {
      this.parents = payload;
    },
    async addWordToGlosary() {
      let newId=this.addTypology({
        name: this.name,
        definition: this.definition,
        source:this.source
      });
      this.nameResult.id = newId;
    },
    create() {
      this.SET_LOADING(true);
      this.SET_LOADING_OPACITY(true);
      if (this.nameResult.id === "") {
        this.addWordToGlosary().then(() => {
          this.createObject();
        });
      } else {
        this.createObject();
      }
    },
    async createObject() {
      try{
        let newData={
          collection:this.collection,
          parents:this.parents,
          name:this.name,
          userName:this.user.name+" "+this.user.surname,
        }
        console.log(newData)
        let result=await this.createNewObject(newData)
        this.$router.push("item/" + result.id);
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "success",
          icon: "done",
          title: "Objeto Creado",
          message:
            "El objeto ha sido agregado exitosamente a la colecci&oacute;n. El c&oacute;digo de identificaci&oacute;n es <strong>" +
            this.newId +
            "</strong>. Edite la informaci&oacute;n.",
          buttonAction: "Editar",
          button: "Crear Nuevo",
          respondTo: "add",
          action: 0,
        });
        this.SET_LOADING(false);
      }catch(error){
        this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "error",
            icon: "error",
            title: "Error al Agregar",
            message:
              "Ocurri&oacute; un error y La palabra no pudo se pudo agregar, error: <br>" +error,
            buttonAction: "",
            button: "Aceptar",
            respondTo: "error",
            action: 0,
          });
          
        this.SET_LOADING(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
div.container {
  width: 100%;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto;
  h2,
  h3,
  h4,
  div {
    label,
    input,
    select {
      width: 100%;
    }
    .autocomplete-container {
      overflow: visible;
      height: 30px;
    }
  }
  h4 {
    text-align: center;
  }
  .note {
    font-size: 14px;
    font-style: italic;
  }
  .visible {
    display: grid !important;
  }
  .options {
    display: none;
    height: 0px;
    overflow: visible;
    margin-top: 0px;
    ul {
      background-color: white;
      border: solid 1px $text;
      margin-block-start: 0px;
      margin-block-end: 0px;
      z-index: 5;
      padding-inline-start: 0px;
      li {
        padding-left: 10px;
        line-height: 27px;
        color: $text;
        cursor: pointer;
        list-style: none;
      }
      li.selected,
      li:hover {
        background-color: $theme;
        color: white;
        font-weight: 500;
      }
    }
  }
  div.image-upload {
    display: grid;
    grid-template-columns: 80% 20%;
    grid-template-rows: auto;
    grid-gap: 10px;
    grid-template-areas:
      "imagen imagen"
      "input button";
    img {
      width: 100%;
      height: auto;
      grid-area: imagen;
    }
    div.input {
      grid-area: input;
      .file {
        display: none;
      }
    }
    button {
      grid-area: button;
      background-color: $theme;
      color: whitesmoke;
      border: none;
      font-weight: 400;
      cursor: pointer;
      border-radius: 10px;
      border: none;
      font-size: 18px;
      line-height: 32px;
      vertical-align: middle;
      i {
        font-size: 32px;
        vertical-align: middle;
      }
    }
  }
  .next-btn {
    background-color: transparent;
    border: none;
    font-weight: 200;
    cursor: pointer;
    font-size: 18px;
    width: auto;
    float: right;
    margin: 8px 16px;
    right: 0%;
    p {
      margin-block-end: 0px;
      margin-block-start: 0px;
      text-decoration: underline;
      vertical-align: middle;
      color: $theme;
      display: inline-block;
    }
    div {
      border-radius: 50px;
      background-color: $theme;
      color: white;
      padding: 5px;
      margin-left: 8px;
      i.material-icons {
        vertical-align: middle;
      }
      display: inline-block;
    }
  }
  .add-btn {
    margin-top: 5px;
    background-color: $theme;
    color: whitesmoke;
    border: none;
    font-weight: 400;
    cursor: pointer;
    border-radius: 10px;
    font-size: 18px;
    line-height: 32px;
    padding: 8px 25px;
    vertical-align: middle;
  }
  button.add-btn:disabled,
  .next-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
