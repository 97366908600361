<template>
  <div class="page-container">
    <div class="show-selector">
      <div>
        <label>Mostrar:</label>
        <select name="show" id="show" v-model="itemsPerPage">
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
        </select>
      </div>
    </div>
    <div class="card-container">
      <router-link :to="'/archive/'+item.id" class="card" v-for="item in list" :key="item.id">
        <div
          class="card-img"
          :style="'background-image:url('+item.imagen+')'"
          v-if="item.imagen!==''"
        ></div>
        <div class="card-img" v-else>
          <i class="material-icons">insert_photo</i>
        </div>
        <div class="middle-icon">
          <div :class="setup.iconPosition">
            <i class="material-icons">add</i>
          </div>
        </div>
        <div class="card-content">
          <h4>{{item.publicData[0].value}}</h4>
          <p>{{item.id}}</p>
          <p class="btns" v-if="setup.buttons.length>0">
            <button
              class="btn-container"
              v-for="(el,index) in setup.buttons"
              :key="item.id+'-'+index"
              @click="$emit('response',{id:item.id,action:el.action})"
            >
              <div class="btn">
                <p>{{el.label}}</p>
                <i class="material-icons">{{el.icon}}</i>
              </div>
            </button>
          </p>
        </div>
      </router-link>
    </div>
    <Pagination :length="pagesNumber" v-model="currentPage" v-on:page-result="setPage" />
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/Pagination.vue";

import {  mapMutations, mapActions } from "vuex";

export default {
  name: "Doc-Viewer",
  components: {
    Pagination
  },
  props: ["setup"],
  data: function() {
    return {
      itemsPerPage: 10,
      currentPage: 1,
      list:[]
    };
  },
  mounted(){
    this.setPage(1);
      this.list=[]
      let count=0
      while(count<this.itemsPerPage){
        if(count<this.setup.lista.length){
          this.list.push(this.setup.lista[count])
        }
        count++
      }
  },
  watch: {
    itemsPerPage(value) {
      this.setPage(1);
      this.list=[]
      let count=0
      while(count<value){
        if(count<this.setup.lista.length){
          this.list.push(this.setup.lista[count])
        }
        count++
      }
    },
    currentPage(){
      this.setCurrentList()
    },
    filteredList(){
      this.setPage(1)
      this.setCurrentList()
    },
    order(value){
      console.log(value)
      this.SET_ORDER(value)
      this.setCurrentList()
    },
    storeOrder(value){
      this.order=value
    }
  },
  computed: {
    pagesNumber() {
      return Math.ceil(this.setup.lista.length / this.itemsPerPage)===0 ? 1:Math.ceil(this.setup.lista.length  / this.itemsPerPage);
    },
    objectsInPage() {
      return this.list;
    }
  },
  methods: {
    ...mapActions(["getSingleItemByQuery"]),
    ...mapMutations([
      "SET_CURRENT_PAGE",
      "MOVE_PAGE",
      "SET_ITEMS_PER_PAGE",
      "SET_LOADING",'SET_LOADING_OPACITY',
      "SET_CURRENT_LIST",
      'SET_ORDER'
    ]),
    setPage(payload) {
      if (payload > 0 && payload <= this.pagesNumber) {
        this.currentPage = payload;
      } else if (payload < 1) {
        this.currentPage = this.pagesNumber;
      } else {
        this.pagesNumber = 1;
      }
    },
    setCurrentList(){
      let count = this.itemsPerPage * (this.currentPage - 1);
      let end=count+this.itemsPerPage
      this.list=[]
      while(count<end){
        if(count<this.setup.lista.length){
          this.list.push(this.setup.lista[count])
        }
        count++
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
@import "../assets/sass/buttons";
@import "../assets/sass/cards";

.page-container {
  width: 100%;
  padding: 10px 1em;
  .show-selector {
    width: 100%;
    position: relative;
    div {
      width: fit-content;
      margin-right: 0px;
      margin-left: auto;
      label {
        width: fit-content;
        margin-right: 5px;
        margin-left: 5px;
      }
      select {
        min-width: 75px;
        width: fit-content;
      }
    }
  }
}
@media (min-width: 768px) {
  .home {
    .section {
      .search-container {
        width: 70%;
      }
    }
    .tabs {
      padding: 15px 10%;
    }
    .section {
      margin: 15px 1em;
      &.side-menu {
        grid-template-columns: auto 260px;
        grid-template-rows: auto;
        grid-template-areas: "objects filter";
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    .section {
      .search-container {
        width: 60%;
      }
    }
    .tabs {
      padding: 15px 15%;
    }
    .section {
      &.side-menu {
        grid-template-columns: auto 350px;
      }
    }
  }
}
</style>
