<template>
  <div class="home">
    <div class="search">
      <div class="content">
        <h1 v-html="'SOBRE'"></h1>
        <h1 v-html="'NOSOTROS'"></h1>
      </div>
    </div>
    <div class="section split color1">
      <div>
        <div class="title">
          <h2 v-html="'Corporación:'"></h2>
          <h1 v-html="'PUERTO'"></h1>
          <h1 v-html="'CRISTAL'"></h1>
        </div>
      </div>
      <div>
        <div class="content">
          <p
            v-html="
              'Corporaci&oacute;n Puerto Cristal es una organizaci&oacute;n sin fines de lucro que trabaja para el resguardo, protección y rescate del Monumento Hist&oacute;rico Campamento Minero Puerto Cristal,  desarrollando iniciativas de investigaci&oacute;n, divulgaci&oacute;n y vinculaci&oacute;n con la comunidad. '
            "
          ></p>
          <p
            v-html="
              'A partir del a&ntilde;o 2014 la familia Casanova Uribe en colaboraci&oacute;n con Corporaci&oacute;n Memoria Austral, iniciaron un trabajo de rescate y puesta en valor de Puerto Cristal, su historia y patrimonio, facilitando el acceso a este Monumento Hist&oacute;rico, y promoviendo la cultura e identidad ligada a este lugar.'
            "
          ></p>
          <p
            v-html="
              'En este contexto, el a&ntilde;o 2017 se cre&oacute; la Corporaci&oacute;n Puerto Cristal, instituci&oacute;n que ha trabajado en la creaci&oacute;n del Museo de Puerto Cristal desarrollando m&uacute;ltiples proyectos que fortalezcan este espacio de aprendizaje, interacci&oacute;n y sociabilizaci&oacute;n del conocimiento ligado a este Campamento Minero.'
            "
          ></p>
        </div>
      </div>
    </div>
    <div class="section color2 split">
      <div><div class="image" id="img1"></div></div>
      <div>
        <div class="content">
          <h1>Directiva de la Corporaci&oacute;n</h1>
          <p
            v-html="
              'Los socios de Corporaci&oacute;n Puerto Cristal corresponden a los integrantes de la Familia Casanova Uribe, propietarios del Campamento Minero. La directiva actual de la Corporaci&oacute;n tiene vigencia hasta el 22 de diciembre 2026 y se encuentra conformada por:'
            "
          ></p>
          <p
            v-html="
              '- Presidente: Luis Bernardo Casanova Uribe.'
            "
          ></p>
          <p
            v-html="
              '- Secretaria: Susana Magdalena Casanova Uribe.'
            "
          ></p>
          <p
            v-html="
              '- Presidente: Alberto Antonio Casanova Uribe.'
            "
          ></p>
        </div>
      </div>
    </div>
    <div class="section full">
      <div>
        <div class="content">
          <h1 v-html="'Trabajo de la Corporaci&oacute;n'"></h1>
          <p
            v-html="
              'Corporaci&oacute;n Puerto Cristal desarrolla distintas l&iacute;neas trabajo que se enmarcan en el siguiente objetivo estrat&eacute;gico que guiar&aacute; el actuar de la organizaci&oacute; a un plazo de 10 a&ntilde;os: “Establecer un espacio museográfico interactivo y didáctico que facilite la comprensión del Campamento Minero Puerto Cristal, que incentive el interés y participación de la comunidad en conocer el pasado minero de Aysén y su patrimonio cultural, que resguarde y exhiba las colecciones del museo y que fomente la investigación en torno a este lugar.”'
            "
          ></p>
          <p
            v-html="
              'En este contexto es que a continuaci&oacute;n se presenta informaci&oacute;n de inter&eacute;s asociada a la labor de la Corporaci&oacute;n:'
            "
          ></p>
          <CardSlider :setup="{ list: col,target:'self' }" />
        </div>
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";
import CardSlider from "@/components/CardSlider.vue";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
    CardSlider,
  },
  data: function () {
    return {
      col: [
      {
          link: "/nosotros/proyectos",
          icon:"engineering",
          title: "Proyectos",
        },
        {
          link: "/nosotros/financiamiento",
          icon:
            "attach_money",
          title: "Financiamiento",
          },
        {
          link: "/nosotros/documentos",
          icon: "description",
          title: "FFOP - Documentos",
          },
      ],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations(["SET_LOADING"]),
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
@import "../assets/sass/buttons";
@import "../assets/sass/cards";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("../assets/media/corporacion.jpg");
      background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    overflow: hidden;
    .video-container {
      position: absolute;
      width: 100%;
      height: 100%;
      video {
        min-width: 100%;
        min-height: 100%;

        /* Setting width & height to auto prevents the browser from stretching or squishing the video */
        width: auto;
        height: auto;

        /* Center the video */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .content {
      background-color: rgba($color: #ffb980, $alpha: 0.6);
      position: relative;
      bottom: 0%;
      padding: 2em;
      margin: auto 2em 2em 2em;
      h3 {
        font-size: 1.4em;
        color: $theme-d3;
        font-weight: 500;
      }
      h1 {
        font-size: 3em;
        font-weight: 700;
        color: $theme-d3;
        margin-block-end: 0;
        margin-block-start: 0;
      }
    }
  }
  .section {
    position: relative;
    padding: 2em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,&.full {
      height: 100%;
      div {
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.2em;
            font-weight: 300;
          }
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("../assets/media/seccion_directorio.jpg");
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 2em;
      &.split {
        grid-template-columns: 1.5fr 2.5fr;
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        grid-template-columns: 1.5fr 2.5fr;
      }
    }
  }
}
</style>
