import app from '@/firebase'
import {
    getFirestore,
    collection,
    doc,
    updateDoc,
    onSnapshot,
    deleteDoc,
    getDoc,
    runTransaction
} from "firebase/firestore";
const db = getFirestore(app)
export default {
    state: {
        items: []
    },
    namespaced: true,
    actions: {
        async getItems({
            commit
        }) {
            try {
                let response=await getDoc(doc(db, 'System', "tipologias"))
                let list=response.data().datos
                list.forEach(el=>{
                    el.estadistica=0
                })
                commit('SET_ITEMS', {
                    list
                })
                return true
            } catch (e) {
                console.log('error', e)
            }
        },
        async addItem({
            commit
        }, payload) {
            let tipRef = await doc(db, "System", "tipologias")
            try{
            let result = await runTransaction(db, async (transaction) => {
                let document = await transaction.get(tipRef)
                let index = document.data().nextValue
                    let id
                    if (index < 10) {
                        id = "000" + index
                    } else if (index > 9 && index < 100) {
                        id = "00" + index
                    } else if (index > 99 && index < 1000) {
                        id = "0" + index
                    } else {
                        id = index
                    }
                    let newIndex = index + 1
                    let code = "tip" + id
                    let arr=document.data().datos
                    arr.push({
                        id:code,
                        ...payload
                    })
                    console.log(arr)
                    transaction.update(tipRef, {
                        datos:arr,
                        nextValue: newIndex
                    })
                    return{
                        datos:arr,
                        id:code
                    }
                    })
                    console.log(result)
                    commit('SET_ITEMS', 
                        result.datos
                    )
                    return result.code
            }catch(e){
                console.log(e)
                return e
            }
        },
        async updateItemName({
            commit
        }, payload) {
            await updateDoc(doc(db, 'Glosario', payload.id), {
                name: payload.name
            })
            commit('UPDATE_ITEM_NAME', payload)
        },
        async updateItemDefinition({
            commit
        }, payload) {
            await updateDoc(doc(db, 'Glosario', payload.id), {
                definition: payload.definition
            })
            commit('UPDATE_ITEM_DEFINITION', payload)
        },
        async deleteItemById({
            commit
        }, payload) {
            await deleteDoc(doc(db, 'Glosario', payload))
            commit('REMOVE_ITEM_BY_ID', payload)
        },
        async getItemObjects({
            commit
        }, payload) {
            onSnapshot(collection(db, 'Glosario/' + payload + '/items'), qS => {
                var arr = []
                qS.forEach(doc => {
                    arr.push(doc.id)
                })
                commit('SET_ITEM_OBJECTS', {
                    id: payload,
                    data: arr
                })
            })
        },
        async setTipologias() {

            let tipologias = [{
                    id: 'tip0001',
                    name: 'Abrazadera',
                    definition: 'Tiras de material flexible, especialmente cuero, usadas para sujetar o mantener objetos unidos. Pueden contener una hebilla u otro cierre adherido TA&A',
                    source: ''
                },
                {
                    id: 'tip0002',
                    name: 'Acople',
                    definition: 'Pieza que sirve para unir una pieza o cuerpo con otro de modo que ajusten exactamente.',
                    source: ''
                },
                {
                    id: 'tip0003',
                    name: 'Agitador de vidrio (química)',
                    definition: 'Una varilla de vidrio, varilla agitadora para agitar,es un instrumento utilizado en los laboratorios de química, consiste en un fino cilindro macizo de vidrio que sirve para agitar disoluciones, con la finalidad de mezclar productos químicos y líquidos. También es utilizado para introducir sustancias químicas liquidas muy reactivas a través del escurrimiento con el fin de evitar accidentes. wikipedia.org',
                    source: ''
                },
                {
                    id: 'tip0004',
                    name: 'Aislador de Alta Tensión',
                    definition: 'Piezas de material aislante empleadas para soportar los conductores eléctricos de las líneas eléctricas de transmisión y distribución.',
                    source: ''
                },
                {
                    id: 'tip0005',
                    name: 'Aislador de disco',
                    definition: 'Masas de material no conductor eléctrico utilizado para mantener conductores cargados de contacto uno con otro o de conexión a tierra y a menudo también para fijarlas. Generalmente se refiere a los objetos de vidrio o porcelana que soportan líneas telefónicas o de alta tensión, pero que no llevan corriente.',
                    source: ''
                },
                {
                    id: 'tip0006',
                    name: 'Aislador loza blanca para cable eléctrico',
                    definition: 'Pieza de cerámica utilizada como aislador eléctrico. Consiste en un cilindro con concavidades donde se enrollan los cables y con un soporte de acero donde se sostiene a una superficie.',
                    source: ''
                },
                {
                    id: 'tip0007',
                    name: 'Alambre de cobre',
                    definition: 'Hilo trefilado compuesto por el elemento metálico puro que tiene como símbolo Cu y como número atómico 29; metal rojizo muy maleable y dúctil que está presente en la corteza terrestre como metal nativo y en minerales de sulfuro, sulfato y carbonato.',
                    source: ''
                },
                {
                    id: 'tip0008',
                    name: 'Altar',
                    definition: 'En una iglesia cristiana, es una mesa sobre la cual se consagran elementos eucarísticos.',
                    source: ''
                },
                {
                    id: 'tip0009',
                    name: 'Amperímetro',
                    definition: 'Instrumento para medir la intensidad de una corriente eléctrica.',
                    source: ''
                },
                {
                    id: 'tip0010',
                    name: 'Ampolla',
                    definition: 'Pequeño recipiente de vidrio cerrado herméticamente, que contiene por lo común una dosis de líquido inyectable.',
                    source: ''
                },
                {
                    id: 'tip0011',
                    name: 'Ancla',
                    definition: 'Instrumento de hierro formado por una barra de la que salen unos ganchos, que, unido a una cadena, se lanza al fondo del agua para sujetar la embarcación.',
                    source: ''
                },
                {
                    id: 'tip0012',
                    name: 'Andador',
                    definition: 'Estructura usada para asistir a la movilidad de personas con discapacidad o capacidades físicas reducidas, o diseñados para ayudar a los bebés a aprender a caminar. TA&A',
                    source: ''
                },
                {
                    id: 'tip0013',
                    name: 'Anillo de Aterrizaje',
                    definition: 'Parte del tubo de perforación interior, tiene dos diámetros diferentes, ya que así el testigo no retrocede',
                    source: 'Elaboración propia'
                },
                {
                    id: 'tip0014',
                    name: 'Anotador',
                    definition: 'Utensilio para sujetar papeles, frecuentemente en forma de pinza, adjunta a una superficie plana.',
                    source: ''
                },
                {
                    id: 'tip0015',
                    name: 'Anotador con sujeta papeles',
                    definition: 'Utensilio para sujetar papeles, frecuentemente en forma de pinza, adjunta a una superficie plana.',
                    source: ''
                },
                {
                    id: 'tip0016',
                    name: 'Arandela',
                    definition: 'Pieza generalmente circular, fina y perforada, que se usa para mantener apretados una tuerca o un tornillo, asegurar el cierre hermético de una junta o evitar el roce entre dos piezas.',
                    source: ''
                },
                {
                    id: 'tip0017',
                    name: 'Archivador',
                    definition: 'Mueble con cajones convenientemente dispuesto paraarchivar documentos, fichas u otros papeles.',
                    source: ''
                },
                {
                    id: 'tip0018',
                    name: 'Armario',
                    definition: 'Mueble con puertas y estantes o perchas para guardar ropa y otros objetos. DRAE',
                    source: ''
                },
                {
                    id: 'tip0019',
                    name: 'Armazón de cama',
                    definition: 'Objeto con patas, sobre la que se colocan un somier o tabla, un colchón, almohada y diversas ropas.',
                    source: ''
                },
                {
                    id: 'tip0020',
                    name: 'Arnés',
                    definition: 'Armazón provista de correas y hebillas que se ata al cuerpo y sirve para sujetar, asegurar o transportar algo o a alguien.',
                    source: ''
                },
                {
                    id: 'tip0021',
                    name: 'Aro Salvavidas',
                    definition: 'Flotador de forma anular que permite sostenerse en la superficie del agua.',
                    source: ''
                },
                {
                    id: 'tip0022',
                    name: 'Atril',
                    definition: 'Mueble en forma de plano inclinado, con pie o sin él, que sirve para sostener libros, partituras, etc., y leer con más comodidad.',
                    source: ''
                },
                {
                    id: 'tip0023',
                    name: 'Bacinica',
                    definition: 'Contenedor de uso doméstico utilizado como orinal. Tiene base plana, paredes generalmente cilíndricas y posee un asa de orientación vertical en el cuerpo. Se encuentra elaborado en loza, metal o metal enlozado, y en la actualidad se han introducido modelos elaborados de plástico. Ocasionalmente posee una tapadera.',
                    source: ''
                },
                {
                    id: 'tip0024',
                    name: 'Balanza',
                    definition: 'Pesas con una barra horizontal que gira alrededor de un fulcro central, creando brazos de longitud similar; suspendidos de los extremos de los brazos hay platillos o canastos, en uno de los cuales se coloca el ítem a ser pesado y en el otro, una pesa previamente determinada. TA&A.',
                    source: ''
                },
                {
                    id: 'tip0025',
                    name: 'Balanza pediátrica',
                    definition: 'Dispositivo mecánico o electrónico que sirve para pesar, diseñado específicamente para pesar bebés, por lo que cuentan con una bandeja grande y cómoda donde acomodar al bebe. La capacidad de las báscula para bebés suele ser de hasta 20 kilos, con la escala de medición en gramos. La función es medir su masa corporal para llevar un control de su proceso de desarrollo y crecimiento.',
                    source: ''
                },
                {
                    id: 'tip0026',
                    name: 'Balde',
                    definition: 'Vasijas redondas usada para captar, contener o llevar líquidos o sólidos.',
                    source: ''
                },
                {
                    id: 'tip0027',
                    name: 'Balón de Fútbol',
                    definition: 'Objeto redondo, con flexibilidad, construido con cuero, goma o plástico.',
                    source: ''
                },
                {
                    id: 'tip0028',
                    name: 'Banca',
                    definition: 'Asiento de madera, sin respaldo y a modo de mesa baja.',
                    source: ''
                },
                {
                    id: 'tip0029',
                    name: 'Bandeja',
                    definition: 'Piezas abiertas de madera, metal u otro material rígido de diferentes formas, con el fondo plano y un borde bajo para depositar, transportar o presentar artículos. TA&A',
                    source: ''
                },
                {
                    id: 'tip0030',
                    name: 'Barra perforadora',
                    definition: 'Pieza de metal u otra materia, de forma generalmente prismática o cilíndrica y mucho más larga que gruesa que se utiliza en labores de perforación.',
                    source: ''
                },
                {
                    id: 'tip0031',
                    name: 'Base de mortero',
                    definition: 'Por definir',
                    source: ''
                },
                {
                    id: 'tip0032',
                    name: 'Batería',
                    definition: 'Objeto recargable para suministrar energía a las lámparas mineras',
                    source: ''
                },
                {
                    id: 'tip0033',
                    name: 'Batería',
                    definition: 'Dispositivo recargable utilizado para alimentar las lámparas mineras',
                    source: ''
                },
                {
                    id: 'tip0034',
                    name: 'Betún para calzado',
                    definition: 'Producto comercial utilizado para dar lustre, impermeabilizar, mejorar la apariencia y aumentar la vida útil de cueros, zapatos o botas.',
                    source: ''
                },
                {
                    id: 'tip0035',
                    name: 'Biblia',
                    definition: 'Se refiere a libros, pergaminos, rollos y otras formas de documento que contienen escrituras sagradas del judaísmo o cristianismo. Las biblias pueden contener iluminaciones, que son escenas o decoraciones pintadas. La Biblia se compone de dos partes: Las Escrituras Hebreas o Viejo Testamento, originalmente escritos en hebreo (con alguna partes en arameo) e incluye las escrituras el pueblo hebreo; y el Nuevo Testamento, escrito en griego y relata la historia de Jesús y de los inicios del cristianismo. Las versiones del Antiguo Testamento del catolicismo romano y ortodoxo oriental, son algo más extensas que la Biblia Protestante debido a que los primeros aceptan ciertos libros y partes de libros considerados apócritos por los protestantes. La Biblia judía sólo incluye los libros conocidos por los cristianos como Antiguo Testamento. La adaptación de cánones judíos y cristianos difiere considerablemente. Tradicionalmente, los judíos han dividido sus escrituras (Antiguo Testamento) en tres partes: La Torá (la "Ley") o Pentateuco; el Nevi' + "'" + 'm (los "Profestas") y el Ketuvim (las "Escrituras") o Hagiografías. Las historias, enseñanzas morales y doctrinas teológicas en la biblia, han provisto de temas para un gran cuerpo de arte visual, tanto para la imaginería cristiana como la judía. Para los cristianos, un canon de libro bíblico fue establecido durante el periodo del cristianismo temprano, sin embargo, varios libros apócrifos continuaron circulando por mucho tiempo. En los inicios del periodo medieval tardío eran muy populares las interpretaciones poéticas y dramáticas de narraciones bíblicas, proporcionando una gran cantidad de literatura extra-canónica que contribuyó a desarrollar importantes temas para el arte cristiano.',
                    source: ''
                },
                {
                    id: 'tip0036',
                    name: 'Bidón',
                    definition: 'Recipientes cilíndricos o rectangulares, generalmente de metal liviano, plástico, o cartón laminado.',
                    source: ''
                },
                {
                    id: 'tip0037',
                    name: 'Bocina',
                    definition: 'Revestimiento metálico con que se guarnece interiormente un orificio.',
                    source: ''
                },
                {
                    id: 'tip0038',
                    name: 'Bolas de molienda',
                    definition: 'Objeto esférico de metal de distintos diámetros, utilizados en el proceso de extracción de mineral.',
                    source: ''
                },
                {
                    id: 'tip0039',
                    name: 'Bota',
                    definition: 'Calzado cuya caña se extiende por sobre la articulación del tobillo.',
                    source: ''
                },
                {
                    id: 'tip0040',
                    name: 'Botella',
                    definition: 'Vasijas que tienen un cuello y una boca considerablemente más angostas que el cuerpo, usadas para embalar y contener líquidos y preparaciones secas. TA&A',
                    source: ''
                },
                {
                    id: 'tip0041',
                    name: 'Bulón',
                    definition: 'Pieza cilíndrica que se utiliza como pasador o perno para unir dos elementos.',
                    source: ''
                },
                {
                    id: 'tip0042',
                    name: 'Bureta',
                    definition: 'Tubo de vidrio graduado, con una llave en su extremo inferior, utilizado para análisis químicos volumétricos. (DRAE)',
                    source: ''
                },
                {
                    id: 'tip0043',
                    name: 'Báscula',
                    definition: 'Aparato que sirve para medir pesos, generalmente grandes.',
                    source: ''
                },
                {
                    id: 'tip0044',
                    name: 'Cabeza de Muñeca',
                    definition: 'Parte superior de un juguete denominado muñeca',
                    source: ''
                },
                {
                    id: 'tip0045',
                    name: 'Cadena Motosierra',
                    definition: 'Herramienta de corte de la motosierra y usualmente cada uno de sus segmentos (construido a partir de secciones metálicas remachadas similares a una cadena de bicicleta, pero sin rodillos) presenta dientes pequeños y muy afilados.',
                    source: ''
                },
                {
                    id: 'tip0046',
                    name: 'Caja',
                    definition: 'Recipiente que, cubierto con una tapa suelta o unida a la parte principal, sirve para guardar o transportar en él algo. DRAE',
                    source: ''
                },
                {
                    id: 'tip0047',
                    name: 'Caja de herramientas',
                    definition: 'Cajas o cajones en los cuales se guardan herramientas.',
                    source: ''
                },
                {
                    id: 'tip0048',
                    name: 'Caja fuerte',
                    definition: 'Caja blindada para guardar dinero y cosas de valor.',
                    source: ''
                },
                {
                    id: 'tip0049',
                    name: 'Cajetilla de cigarrillos',
                    definition: 'Paquete de cigarrillos.',
                    source: ''
                },
                {
                    id: 'tip0050',
                    name: 'Cajón',
                    definition: 'Receptáculo que se ajusta a un hueco determinado de un armario, mesa, cómoda u otro mueble, en el que entra y del que se puede sacar.',
                    source: ''
                },
                {
                    id: 'tip0051',
                    name: 'Calculadora',
                    definition: 'Aparato o máquina que, por unprocedimiento mecánico o electrónico, resuelve cálculos matemáticos.',
                    source: ''
                },
                {
                    id: 'tip0052',
                    name: 'Calculadora con impresora eléctrica',
                    definition: 'Dispositivo electrónico que realiza cálculos que requieren una intervención manual para cada operación individual, en este caso específico además posee un dispositivos de salida que convierten la información codificada del procesador en un formato legible en papel.',
                    source: ''
                },
                {
                    id: 'tip0053',
                    name: 'Caldera',
                    definition: 'En una instalación de calefacción, aparato dotado de una fuente de energía, donde se calienta el agua que circula por tubos y radiadores.',
                    source: ''
                },
                {
                    id: 'tip0054',
                    name: 'Calendario',
                    definition: 'Sistema de representación del paso de los días, agrupados en unidades superiores, como semanas, meses, años, etc.',
                    source: ''
                },
                {
                    id: 'tip0055',
                    name: 'Cama de Exploración',
                    definition: 'Plaza para un enfermo en el hospital o sanatorio.',
                    source: ''
                },
                {
                    id: 'tip0056',
                    name: 'Camilla',
                    definition: 'Úsese para designar una variedad de medios de transportes para una sola persona, consistentes, por lo general, en lienzos o materiales similares tensados o estirados sobre una armazón, utilizados para trasladar a enfermos, heridos o fallecidos. Para los medios de transportes más elaborados, llevados sobre postes por personas o animales, use “litera”, si tienen forma de caja, encerrada frecuentemente entre cortinas; o “sedan chairs”, si tienen forma de sillas portátiles.',
                    source: ''
                },
                {
                    id: 'tip0057',
                    name: 'Camilla',
                    definition: 'Úsese para designar una variedad de medios de transportes para una sola persona, consistentes, por lo general, en lienzos o materiales similares tensados o estirados sobre una armazón, utilizados para trasladar a enfermos, heridos o fallecidos. ',
                    source: ''
                },
                {
                    id: 'tip0058',
                    name: 'Camiseta tipo polo',
                    definition: 'Camisa pullover con mangas cortas o largas y un cuello cambiable o una banda de cuello redondeada. Generalmente, realizada en tejido suave y absorbente, tales como algodón tejido.',
                    source: ''
                },
                {
                    id: 'tip0059',
                    name: 'Campana',
                    definition: 'Vasija de percusión que consiste de un objeto hueco, por lo general de metal pero en algunas culturas de greda endurecida, madera, o vidrio, que al golpearse, por un un badajo o por un martillo exterior, emite un sonido por la vibración de la mayor parte de su masa; se mantiene en posición en su vértice, el punto más alejado de su borde, siendo la zona cercana a su borde el punto de máxima vibración.',
                    source: ''
                },
                {
                    id: 'tip0060',
                    name: 'Campana de lámpara',
                    definition: 'Dispositivos, generalmente de vidrio, suspendidos por encima de los dispositivos de iluminación con una lumbre para proteger el techo de depósitos de hollín. TA&A',
                    source: ''
                },
                {
                    id: 'tip0061',
                    name: 'Campana para depósito de inodoro',
                    definition: 'Instrumento utilizado en la parte interna de un deposito de agua de hierro.',
                    source: ''
                },
                {
                    id: 'tip0062',
                    name: 'Canasta basketball',
                    definition: 'En baloncesto, aro metálico sujeto horizontalmente a un tablero vertical, y del que pende una red tubular sin fondo en la que es necesario introducir el balón para el enceste.',
                    source: ''
                },
                {
                    id: 'tip0063',
                    name: 'Candelabro',
                    definition: 'Soporte para velas que consiste en un candelero de uno o más brazos, que puede sostenerse sobre su pie o ir sujeto a una pared o a otra superficie.',
                    source: ''
                },
                {
                    id: 'tip0064',
                    name: 'Capilla de extracción de gases',
                    definition: 'Equipo de protección o dispositivo de ventilación local que está diseñado para limitar la exposición a sustancias químicas peligrosas o nocivas, humos, vapores o polvos.',
                    source: ''
                },
                {
                    id: 'tip0065',
                    name: 'Carro Minero',
                    definition: 'También conocido como vagón minero, es un tipo de maquinaria de minería subterránea utilizada en extracción e minas subterráneas, especialmente en minas de cámara y pilar, para el transporte de materiales o minerales.',
                    source: ''
                },
                {
                    id: 'tip0066',
                    name: 'Carrusel',
                    definition: 'Úsese para cualquiera de varios objetos compuestos generalmente de dos o más asientos ubicados a lo largo de un circulo o fierros en forma circular, para que de esta forma puedan girar en torno a un eje y diseñados para recreación.',
                    source: ''
                },
                {
                    id: 'tip0067',
                    name: 'Cartel',
                    definition: 'Letreros destinados a ser exhibidos para anunciar, promocionar, o publicitar una actividad, causa, producto, o servicio; también impresiones decorativas producidas para distribución masiva y para ser colgadas.',
                    source: ''
                },
                {
                    id: 'tip0068',
                    name: 'Casco de Bomberos',
                    definition: 'Casco que llevan los bomberos; tiene un remate cupulado y un ala extendido en la parte posterior para servir como guarda del cuello.',
                    source: ''
                },
                {
                    id: 'tip0069',
                    name: 'Casco de Minero',
                    definition: 'Equipo de protección de la cabeza para los trabajadores de las minas. Se utiliza con fines de seguridad para los mineros, sobre todo en la minería subterránea. Suelen tener un foco de luz colocado en la zona frontal.',
                    source: ''
                },
                {
                    id: 'tip0070',
                    name: 'Casete',
                    definition: 'El casete, también conocido como casete compacto o cassette (en francés, ‘cajita’), es un formato de grabación de sonido o vídeo en cinta magnética que fue ampliamente utilizado entre principios de los años 1970 y fines de la década de 1990.',
                    source: ''
                },
                {
                    id: 'tip0071',
                    name: 'Catálogo',
                    definition: 'Relación ordenada en la que se incluyen o describen de forma individual objetos que están relacionados entre sí.',
                    source: ''
                },
                {
                    id: 'tip0072',
                    name: 'Cañería inyector',
                    definition: 'Objeto fabricado con acero de alto grosor que posee terminales cónicos y es comúnmente utilizado en maquinaria y motores.',
                    source: ''
                },
                {
                    id: 'tip0073',
                    name: 'Cenicero',
                    definition: 'Receptáculos para las cenizas de tabaco de fumadores, fabricados en una gran variedad de tamaños y diseños.',
                    source: ''
                },
                {
                    id: 'tip0074',
                    name: 'Cepillo Carpintero',
                    definition: 'Instrumento de carpintería formado por un prisma cuadrangular de madera dura, que lleva embutido, en una abertura transversal y sujeto por una cuña, un hierro acerado con filo, el cual sobresale un poco de la cara que ha de frotar con la madera cuya superficie se quiere alisar.',
                    source: ''
                },
                {
                    id: 'tip0075',
                    name: 'Chancador de Cono',
                    definition: 'El chancador de cono es un tipo de equipo de compresión que reduce el material mediante la compresión de este material de alimentación entre una pieza de acero móvil y una pieza de acero fija. La reducción y el calibrado de tamaño finales se determinan a través de la configuración en el lado de cierre o el espacio entre los dos miembros de chancado en el punto más bajo. Cuando la cuña o el eje excéntrico giran para provocar la compresión dentro de la cámara, el material se reduce en tamaño al moverse hacia abajo a través del revestimiento de desgaste a medida que se estrecha la abertura de la cavidad. El material chancado se descarga en la parte inferior del equipo después de que pasa a través de la cavidad.',
                    source: ''
                },
                {
                    id: 'tip0076',
                    name: 'Chancador de Mandíbula',
                    definition: 'Es un equipo diseñado para producir un impacto sobre una partícula de roca que se posiciona entre una placa fija y otra móvil, los recubrimientos de estas placas están hechos de acero reforzado. La placa móvil aplica fuerza de impacto sobre la partícula que es presionada contra la placa estacionaria. La placa móvil puede pivotear en la parte superior, media o inferior. Son equipos que se utilizan generalmente en el chancado primario en la industria minera.',
                    source: ''
                },
                {
                    id: 'tip0077',
                    name: 'Chapa',
                    definition: 'Lámina de un material duro, especialmente madera o metal.',
                    source: ''
                },
                {
                    id: 'tip0078',
                    name: 'Chaqueta',
                    definition: 'Prenda exterior de vestir, con mangas y abierta por delante, que llega por debajo de la cadera.',
                    source: ''
                },
                {
                    id: 'tip0079',
                    name: 'Chaqueta impermeable',
                    definition: 'Tipo de ropa de agua. Es una gabardina no permeable al agua, principalmente para proteger de la lluvia, hecho de material plastificado o laminado con plástico o -antes de la existencia del plástico- con goma.',
                    source: ''
                },
                {
                    id: 'tip0080',
                    name: 'Chimenea',
                    definition: 'Dispositivos tubulares de vidrio, metal o mica que se extienden hacia arriba desde el borde del quemador y concentran el aire caliente que sale de la llama, produciendo así un chorro.',
                    source: ''
                },
                {
                    id: 'tip0081',
                    name: 'Choquero',
                    definition: 'En Chile, vasija generalmente de lata con asa o agarradera de alambre que se utiliza para calentar bebidas.',
                    source: 'Definición Propia'
                },
                {
                    id: 'tip0082',
                    name: 'Ciclón',
                    definition: 'Equipo utilizado para separar partículas sólidas suspendidas en el aire, gas o flujo de líquido, sin el uso de un filtro de aire, utilizando un vórtice para la separación.',
                    source: ''
                },
                {
                    id: 'tip0083',
                    name: 'Cinta Transportadora',
                    definition: 'Cintas continuas que pasan sobre poleas, proporcionando una pista en la que se lleva materiales sueltos o pequeños artículos de un punto a otro.',
                    source: ''
                },
                {
                    id: 'tip0084',
                    name: 'Cinta adhesiva',
                    definition: 'Una cinta recubierta por una cara con una mezcla adhesiva que se adhiere a una superficie mediante presión ligera, a diferencia de la cinta utilizada con adhesivo que requiere la adición de humedad o de otro disolvente para adherirse.',
                    source: ''
                },
                {
                    id: 'tip0085',
                    name: 'Cinta magnética',
                    definition: 'Tipo de medio o soporte de almacenamiento de datos que se graba en pistas sobre una banda plástica con un material magnetizado, generalmente óxido de hierro o algún cromato.',
                    source: ''
                },
                {
                    id: 'tip0086',
                    name: 'Cinta métrica',
                    definition: 'Instrumento de medición, que cuenta con unas líneas marcadas longitudinalmente donde se pueden observar las unidades de medidas y sus divisiones.',
                    source: ''
                },
                {
                    id: 'tip0087',
                    name: 'Cinturón',
                    definition: 'Tira provista de una hebilla, que se usa para sujetar o ceñir a la cintura una prenda de vestir.',
                    source: ''
                },
                {
                    id: 'tip0088',
                    name: 'Cirio',
                    definition: 'Masas cilíndricas típicamente de sebo o cera que contienen una mecha o hebras torcidas sueltamente de lino o algodón quemadas para dar luz.',
                    source: ''
                },
                {
                    id: 'tip0089',
                    name: 'Clasificador',
                    definition: 'Utensilio o sistema provisto de divisiones o compartimentos adecuados para la clasificación de papeles u otras cosas.',
                    source: ''
                },
                {
                    id: 'tip0090',
                    name: 'Clavo rielero',
                    definition: 'Es un herraje grande con una cabeza offset, se utiliza para asegurar rieles y placas de base a los durmientes de la vía.',
                    source: ''
                },
                {
                    id: 'tip0091',
                    name: 'Codo',
                    definition: 'Trozo de tubo, doblado en ángulo o en arco, que sirve para variar la dirección recta de una tubería.',
                    source: 'RAE'
                },
                {
                    id: 'tip0092',
                    name: 'Columpio',
                    definition: 'Úsese para cualquiera de varios objetos compuestos generalmente de un asiento o asientos colgados desde arriba, por cuerdas, cadenas o ganchos, para que se balanceen libremente y diseñados para recreación, como aquellos que se encuentran en las áreas de juego.',
                    source: ''
                },
                {
                    id: 'tip0093',
                    name: 'Compresor',
                    definition: 'Máquina que sirve para reducir a menor volumen un líquido o un gas por medio de la presión.',
                    source: ''
                },
                {
                    id: 'tip0094',
                    name: 'Conector de manguera',
                    definition: 'Elemento que conecta una pieza con otra, en este caso, es para conectar mangueras de agua o aire.',
                    source: ''
                },
                {
                    id: 'tip0095',
                    name: 'Contrapeso balanza',
                    definition: 'Peso que se pone en la parte contraria de otro para que queden en equilibrio',
                    source: ''
                },
                {
                    id: 'tip0096',
                    name: 'Contrapeso balanza antigua',
                    definition: 'Peso que se pone en la parte contraria de otro para que queden en equilibrio',
                    source: ''
                },
                {
                    id: 'tip0097',
                    name: 'Corbata',
                    definition: 'Prenda de adorno, especialmente masculina, consistente en una banda larga y estrecha de seda u otro tejido adecuado, que, colocada alrededor del cuello, se anuda o enlaza por delante dejando caer sus extremos sobre el pecho.',
                    source: ''
                },
                {
                    id: 'tip0098',
                    name: 'Corona',
                    definition: 'Aro, hecho de flores, de ramas o de metal, que ciñe la cabeza y se usa como adorno, insignia honorífica o símbolo de dignidad o realeza.',
                    source: ''
                },
                {
                    id: 'tip0099',
                    name: 'Corona perforación',
                    definition: 'Herramienta de corte que puede llevar diamantes naturales o sintéticos, incrustados o impregnados en una matriz de aleación con tungsteno.',
                    source: ''
                },
                {
                    id: 'tip0100',
                    name: 'Correa (Herramienta)',
                    definition: 'Bandas continuas de material resistente, flexible, tal como cuero, tela, caucho, o alambre; utilizadas para transmitir movimiento y potencia de una polea a otra, o para materiales de transporte.',
                    source: ''
                },
                {
                    id: 'tip0101',
                    name: 'Correa para poste',
                    definition: 'Tira de cuero u otro material que sirve para sostenerse en altura.',
                    source: ''
                },
                {
                    id: 'tip0102',
                    name: 'Cortaperno',
                    definition: 'Cizallas de mano para la construcción usadas para el corte de pernos en dos. TA&A',
                    source: ''
                },
                {
                    id: 'tip0103',
                    name: 'Cortina',
                    definition: 'Telas colgantes para ventanas, puertas de entrada, o otras aberturas que generalmente terminan en bastas, encajonamientos, pliegues o fruncidos y cuelgan de la parte de arriba; usadas por privacidad, control de la luz y corrientes de aire, o decoración.',
                    source: ''
                },
                {
                    id: 'tip0104',
                    name: 'Crisol',
                    definition: 'Vasijas hechas de material refractario, como arcilla, grafito o porcelana, o un metal relativamente difícil de fundir, que pueden variar en tamaño y que se usan para fundir, calcinar o filtrar una sustancia a altas temperaturas. TA&A',
                    source: ''
                },
                {
                    id: 'tip0105',
                    name: 'Cruz',
                    definition: 'Insignia y señal de cristiano, en memoria de haber padecido en ella Jesucristo.',
                    source: ''
                },
                {
                    id: 'tip0106',
                    name: 'Cuaderno',
                    definition: 'Registros en los que se ingresa información secuencial, especialmente acerca de actividades o transacciones que ocurren en un sistema.',
                    source: ''
                },
                {
                    id: 'tip0107',
                    name: 'Cuadro',
                    definition: 'Composición pictórica desarrollada sobre lienzo, madera, papel, etc., generalmente enmarcada.',
                    source: ''
                },
                {
                    id: 'tip0108',
                    name: 'Cuchara',
                    definition: 'Utensilio que se compone de una parte cóncava prolongada en un mango, y que sirve, especialmente, para llevar a la boca cosas líquidas, blandas o menudas. DRAE',
                    source: ''
                },
                {
                    id: 'tip0109',
                    name: 'Cuchilla',
                    definition: 'Instrumento de metal acerado, que se usa en diversas partes para cortar.',
                    source: ''
                },
                {
                    id: 'tip0110',
                    name: 'Cuchillo',
                    definition: 'Instrumento para cortar formado por una hoja de metal de un corte solo y con mango.',
                    source: ''
                },
                {
                    id: 'tip0111',
                    name: 'Cuna',
                    definition: 'Cama pequeña para infantes, con bordes altos o barandillas laterales, aveces dispuesta para poderla mecer',
                    source: ''
                },
                {
                    id: 'tip0112',
                    name: 'Cédula de indentdad',
                    definition: 'Documento expedido a favor de una persona, generalmente en forma de tarjeta y provisto de su fotografía, que sirve para acreditar su identidad, su pertenencia a un colectivo o su facultad para realizar una actividad.',
                    source: ''
                },
                {
                    id: 'tip0113',
                    name: 'Dado para Terraja de Roscar',
                    definition: 'Repuesto para herramienta formada por una barra de acero con una caja rectangular en el medio, donde se ajustan las piezas que sirven para labrar las roscas de los tornillos.',
                    source: ''
                },
                {
                    id: 'tip0114',
                    name: 'Damajuana',
                    definition: 'Botella cubierta con mimbre, con una capacidad de un cuarto hasta diez galones, para líquidos no corrosivos y sin cuerpo.',
                    source: ''
                },
                {
                    id: 'tip0115',
                    name: 'Delantal',
                    definition: 'Prenda, con peto o sin él, que atada a la cintura se usa para proteger la ropa en tareas domésticas o profesionales.',
                    source: ''
                },
                {
                    id: 'tip0116',
                    name: 'Depresor lingual',
                    definition: 'Instrumento médico para deprimir la lengua y permitir el examen de la boca y la garganta.',
                    source: ''
                },
                {
                    id: 'tip0117',
                    name: 'Depósito para enemas',
                    definition: 'Recipiente donde se deposita agua para realizar enemas.',
                    source: ''
                },
                {
                    id: 'tip0118',
                    name: 'Desecador',
                    definition: 'Instrumento de vidrio de uso de laboratorio que se utiliza para mantener limpia y deshidratada una sustancia por medio del vacío.',
                    source: ''
                },
                {
                    id: 'tip0119',
                    name: 'Destilador de Agua',
                    definition: 'El destilador de agua es un instrumento de laboratorio que se usa para purificar el agua corriente, mediante procesos controlados de vaporización y enfriamiento.',
                    source: ''
                },
                {
                    id: 'tip0120',
                    name: 'Discos de vinilo',
                    definition: 'Medio de almacenamiento de sonido analógico en forma de discos de policloruro de vinilo, los cuales se estrían en una forma espiral modulada.',
                    source: ''
                },
                {
                    id: 'tip0121',
                    name: 'Documento',
                    definition: 'Diploma, carta, relación u otro escrito que ilustra acerca de algún hecho, principalmente de los históricos.',
                    source: ''
                },
                {
                    id: 'tip0122',
                    name: 'Dominó',
                    definition: 'Bloques planos, rectangulares del tamaño del pulgar, generalmente de hueso, marfil o madera, la cara superior de cada uno es dividida transversalmente en dos partes iguales por una línea; cada parte está, o en blanco, o lleva de uno a seis puntos.',
                    source: ''
                },
                {
                    id: 'tip0123',
                    name: 'Embalaje',
                    definition: 'Úsese para materiales de embalaje o envoltorio protector de productos que contienen información o publicidad acerca del producto. Para material que se usa con el objeto de proteger, amortiguar o reforzar objetos para un embarque o almacenamiento seguros, y que no lleva información o mensaje alguno acerca de los objetos, use "material de embalaje".',
                    source: ''
                },
                {
                    id: 'tip0124',
                    name: 'Embudo',
                    definition: 'Utensilios consistentes de un cono invertido con un tubo estrecho en el apéndice, usado para conducir líquidos u otras sustancias de un contenedor a otro, usualmente con una apertura estrecha; algunas veces combinada con un colador.',
                    source: ''
                },
                {
                    id: 'tip0125',
                    name: 'Empaquetadura',
                    definition: 'Guarnición de cáñamo, amianto, goma u otros materiales que se coloca en determinadas partes de algunas máquinas para impedir el escape de un fluido.',
                    source: ''
                },
                {
                    id: 'tip0126',
                    name: 'Enchufe (Caja de salida)',
                    definition: 'Terminales de un sistema de electricidad hecho para recibir las clavijas de un enchufe o de otros medios de suministrar corriente a un aparato o dispositivo.',
                    source: ''
                },
                {
                    id: 'tip0127',
                    name: 'Engranaje',
                    definition: 'Ruedas dentadas, conos o cilindros u otros elementos mecanizados diseñado para engranar con otros elementos, igualmente dientes para fines que incluyen la transmisión de la energía y el cambio de velocidad o dirección. TA&A',
                    source: ''
                },
                {
                    id: 'tip0128',
                    name: 'Envase Choritos',
                    definition: 'Producto que puede estar fabricado en una gran cantidad de materiales y que sirve para contener, proteger, manipular, distribuir y presentar mercancías en cualquier fase de su proceso productivo, de distribución o de venta.',
                    source: ''
                },
                {
                    id: 'tip0129',
                    name: 'Envase Crema Nivea',
                    definition: 'Aquello que envuelve o contiene artículos de comercio u otros efectos para conservarlos o transportarlos.',
                    source: ''
                },
                {
                    id: 'tip0130',
                    name: 'Envase Mentalol',
                    definition: 'Aquello que envuelve o contiene artículos de comercio u otros efectos para conservarlos o transportarlos.',
                    source: ''
                },
                {
                    id: 'tip0131',
                    name: 'Envase Polvos Royal',
                    definition: 'Producto que puede estar fabricado en una gran cantidad de materiales y que sirve para contener, proteger, manipular, distribuir y presentar mercancías en cualquier fase de su proceso productivo, de distribución o de venta.',
                    source: ''
                },
                {
                    id: 'tip0132',
                    name: 'Envase Sal',
                    definition: 'Producto que puede estar fabricado en una gran cantidad de materiales y que sirve para contener, proteger, manipular, distribuir y presentar mercancías en cualquier fase de su proceso productivo, de distribución o de venta.',
                    source: ''
                },
                {
                    id: 'tip0133',
                    name: 'Envase de Pintura',
                    definition: 'Recipientes, comúnmente cilíndricos, que sirven para guardar pintura.',
                    source: ''
                },
                {
                    id: 'tip0134',
                    name: 'Envase de agorex',
                    definition: 'Producto que puede estar fabricado en una gran cantidad de materiales y que sirve para contener, proteger, manipular, distribuir y presentar mercancías en cualquier fase de su proceso productivo, de distribución o de venta.',
                    source: ''
                },
                {
                    id: 'tip0135',
                    name: 'Envase de medicinas',
                    definition: 'Recipientes cerrados herméticamente para alimentos o bebidas. También otros usados para guardar o llevar líquidos, como aceiteras o recipientes de metal o plásticos para guardar películas en machos o rollos',
                    source: ''
                },
                {
                    id: 'tip0136',
                    name: 'Envase de té',
                    definition: 'Recipientes de cerrados herméticamente para alimentos o bebidas. También otros usados para guardar o llevar líquidos, como aceiteras o recipientes de metal o plásticos para guardar películas en machos o rollos.',
                    source: ''
                },
                {
                    id: 'tip0137',
                    name: 'Envase te',
                    definition: 'Recipientes cerrados herméticamente para alimentos o bebidas. También otros usados para guardar o llevar líquidos, como aceiteras o recipientes de metal o plásticos para guardar películas en machos o rollos.',
                    source: ''
                },
                {
                    id: 'tip0138',
                    name: 'Escala',
                    definition: 'Marcos, por lo general de madera o metal, consistentes en dos piezas laterales llamadas montantes verticales que están conectados por piezas transversales, por lo general redondas, llamados peldaños; utilizados como un medio de subir o bajar.',
                    source: ''
                },
                {
                    id: 'tip0139',
                    name: 'Escalera Gimnasia',
                    definition: 'Armazón de hierro en forma de pórtico para juegos y recreación infantil.',
                    source: ''
                },
                {
                    id: 'tip0140',
                    name: 'Escariador',
                    definition: 'Herramienta utilizada en la actividad de sondaje que permite mantener el diámetro de la perforación a lo largo del tiro.',
                    source: ''
                },
                {
                    id: 'tip0141',
                    name: 'Escritorio',
                    definition: 'Diversas formas de muebles para lectores o escritores, que generalmente tienen una superficie de escritura plana y a menudo cajones u otros compartimientos.',
                    source: ''
                },
                {
                    id: 'tip0142',
                    name: 'Escudo facial',
                    definition: 'Protector de los ojos que, además de los ojos, cubren la cara, garganta y cuello, pudiendo ser llevados sobre la cabeza bien directamente mediante un arnés de cabeza o con un casco protector.',
                    source: ''
                },
                {
                    id: 'tip0143',
                    name: 'Esmeril',
                    definition: 'Roca negruzca formada por corindón granoso, mica y hierro oxidado que, por su extrema dureza, se utiliza para pulimentar y cortar metales, labrar piedras preciosas, etc.',
                    source: ''
                },
                {
                    id: 'tip0144',
                    name: 'Estandarte',
                    definition: 'Insignia que usan las corporaciones civiles y religiosas. Consiste en un pedazo de tela generalmente cuadrilongo, donde figura la divisa de aquellas, y lleva su borde superior fijo en una vara que pende horizontal de un astil con el cual forma cruz.',
                    source: ''
                },
                {
                    id: 'tip0145',
                    name: 'Estator',
                    definition: 'Parte fija o inmóvil de un motor o generador eléctrico, que se contraponen al rotor. Está constituido por la propia carcasa de la turbina.',
                    source: ''
                },
                {
                    id: 'tip0146',
                    name: 'Estatuilla Religiosa',
                    definition: 'Obra tridimensional, escultura exenta que representa una figura de menos de la mitad de su tamaño natural y eventualmente puede no ser figurativa. En este caso representa imágenes de culto o religiosas',
                    source: 'Tesauro de Arte & Arquitectura'
                },
                {
                    id: 'tip0147',
                    name: 'Estetoscopio de pinar',
                    definition: 'Aparato destinado a auscultar los latidos del bebe en las mujeres embarazadas, ampliándolos con la menor deformación posible.',
                    source: ''
                },
                {
                    id: 'tip0148',
                    name: 'Estufa',
                    definition: 'Aparato destinado a calentar un recinto por electricidad o combustión de madera, gas, etc.',
                    source: ''
                },
                {
                    id: 'tip0149',
                    name: 'Estátor',
                    definition: 'Parte fija de una máquina dentro de la cual gira un rotor.',
                    source: ''
                },
                {
                    id: 'tip0150',
                    name: 'Fichas Numeradas',
                    definition: 'Piezas pequeñas que, a modo de contraseña, se usa en guardarropas, aparcamientos y sitios análogos.',
                    source: 'RAE'
                },
                {
                    id: 'tip0151',
                    name: 'Filtros de aire',
                    definition: 'Dispositivo que elimina partículas sólidas como por ejemplo polvo, polen y bacterias del aire.',
                    source: ''
                },
                {
                    id: 'tip0152',
                    name: 'Florero',
                    definition: 'Vaso para poner flores.',
                    source: ''
                },
                {
                    id: 'tip0153',
                    name: 'Fragua',
                    definition: 'Fogón en que se caldean los metales para forjarlos, avivando el fuego mediante una corriente horizontal de aire producida por un fuelle o por otro aparato análogo.',
                    source: ''
                },
                {
                    id: 'tip0154',
                    name: 'Frasco',
                    definition: 'Vaso de cuello recogido, hecho de vidrio u otra materia, que sirve para contener líquidos, sustancias en polvo, comprimidos,',
                    source: ''
                },
                {
                    id: 'tip0155',
                    name: 'Fuente',
                    definition: 'Plato grande, más o menos hondo, que se usa para servir los alimentos',
                    source: ''
                },
                {
                    id: 'tip0156',
                    name: 'Fusibles Tipo Botella',
                    definition: 'Componente eléctrico hecho de un material conductor, generalmente estaño, que tiene un punto de fusión muy bajo y se coloca en un punto del circuito eléctrico para interrumpir la corriente cuando esta es excesiva.',
                    source: ''
                },
                {
                    id: 'tip0157',
                    name: 'Gafa de Protección',
                    definition: 'Revestimientos de protección para los ojos con lentes especiales con placas a los lados y en ocasiones proyecta monturas o tubos oculares, ya sea en su lugar por dos piezas laterales que se extienden alrededor de los orejas o una correa que pasa alrededor de la nuca.',
                    source: ''
                },
                {
                    id: 'tip0158',
                    name: 'Gancho',
                    definition: 'Castilletes o elementos curvos usados para suspender o sujetar objetos o para asegurar objetos a una superficie.',
                    source: ''
                },
                {
                    id: 'tip0159',
                    name: 'Gasa',
                    definition: 'Banda de tejido muy ralo, que, esterilizada o impregnada de sustancias medicamentosas, se usa en cirugía.',
                    source: ''
                },
                {
                    id: 'tip0160',
                    name: 'Golilla',
                    definition: 'Pieza delgada, generalmente circular y con un orificio en el centro, que sirve para mantener apretados una tuerca o tornillo, asegurar el cierre hermético de una junta o evitar el roce entre dos piezas.',
                    source: ''
                },
                {
                    id: 'tip0161',
                    name: 'Gradilla',
                    definition: 'Utensilio que se utiliza en los laboratorios para mantener verticales y ordenados los tubos de ensayo',
                    source: ''
                },
                {
                    id: 'tip0162',
                    name: 'Gradilla',
                    definition: 'Utensilio que se utiliza en los laboratorios para mantener verticales y ordenados los tubos de ensayo.',
                    source: ''
                },
                {
                    id: 'tip0163',
                    name: 'Granada contra incendios',
                    definition: 'Objeto extintor de incendios del siglo XIX.',
                    source: ''
                },
                {
                    id: 'tip0164',
                    name: 'Granadas contra Incendios',
                    definition: 'Antiguas granadas extinguidoras de incendios del siglo XIX. (entre 1860-1890) Hechas a mano en vidrio soplado. En su interior poseen un químico extintor.',
                    source: ''
                },
                {
                    id: 'tip0165',
                    name: 'Guantes de protección',
                    definition: 'Cobertura para la mano que guarda cada dedo separadamente; a veces se extiende a la muñeca y el brazo. Suele usarse en pares. Para realizar trabajos de soldadura o fundición donde haya el riesgo de quemaduras con material incandescente, se recomienda el uso de guantes y mangas resistentes al calor.',
                    source: ''
                },
                {
                    id: 'tip0166',
                    name: 'Guillotina',
                    definition: 'Máquinas de corte que consisten esencialmente en una sola hoja pesada que desciende entre guías verticales para cortar o recortar papel, metal u otros materiales.',
                    source: ''
                },
                {
                    id: 'tip0167',
                    name: 'Guía',
                    definition: 'Información impresa referente a equipos de seguridad.',
                    source: ''
                },
                {
                    id: 'tip0168',
                    name: 'Hacha de tala',
                    definition: 'Herramienta con un filo metálico que está fijado de forma segura a un mango, generalmente de madera, cuya finalidad es el corte mediante golpes.',
                    source: ''
                },
                {
                    id: 'tip0169',
                    name: 'Herradura',
                    definition: 'Hierro aproximadamente semicircular que se clava a las caballerías en los cascos o a algunos vacunos en las pezuñas para que no se los maltraten con el piso. RAE',
                    source: ''
                },
                {
                    id: 'tip0170',
                    name: 'Horno',
                    definition: 'Construcción de piedra o ladrillo para caldear, en general abovedada y provista de respiradero o chimenea y de una o varias bocas por donde se introduce lo que se trata de someter a la acción del fuego.',
                    source: ''
                },
                {
                    id: 'tip0171',
                    name: 'Horno de secado',
                    definition: 'Horno o recinto calefaccionado para el secado, carbonización, endurecimiento, horneado, o quema de materiales diversos. TA&A',
                    source: ''
                },
                {
                    id: 'tip0172',
                    name: 'Huso',
                    definition: 'Instrumento manual, generalmente de madera, de forma redondeada, más largo que grueso, que va adelgazándose desde el medio hacia las dos puntas, y sirve para hilar torciendo la hebra y devanando en él lo hilado.',
                    source: ''
                },
                {
                    id: 'tip0173',
                    name: 'Hélice',
                    definition: 'Conjunto de aletas que giran alrededor de un eje y empujan el fluido ambiente produciendo en él una fuerza de reacción que se utiliza principalmente para la propulsión de barcos y aeronaves.',
                    source: ''
                },
                {
                    id: 'tip0174',
                    name: 'Imagen de Culto',
                    definition: 'Imágenes pictóricas o escultóricas que son veneradas dentro de un sistema particular de culto. Para objetos usados de manera similar pero que no contienen imágenes, use "objeto religioso" u "objeto de culto".',
                    source: ''
                },
                {
                    id: 'tip0175',
                    name: 'Infantómetro',
                    definition: 'Instrumento diseñado para medir la longitud de los niños menores de 100 cm.',
                    source: ''
                },
                {
                    id: 'tip0176',
                    name: 'Infiernillo eléctrico',
                    definition: 'Utensilio eléctrico y portátil destinado a calentar o cocinar.',
                    source: ''
                },
                {
                    id: 'tip0177',
                    name: 'Infiernillo eléctrico doble',
                    definition: 'Utensilio eléctrico y portátil destinado a calentar o cocinar.',
                    source: ''
                },
                {
                    id: 'tip0178',
                    name: 'Jack Hammer',
                    definition: 'Es una maquina neumática que sirve para la realización de taladros que varían de (30-45mm de diámetro) para trabajo horizontal o al piso.',
                    source: ''
                },
                {
                    id: 'tip0179',
                    name: 'Jack Leg',
                    definition: 'Es una maquina neumática que sirve para la realización de taladros que varían de (30-45mm de diámetro) para trabajo horizontal o al piso.',
                    source: ''
                },
                {
                    id: 'tip0180',
                    name: 'Jarra',
                    definition: 'Vasija de barro, porcelana, loza, cristal, etc., con cuello y boca anchos y una o dos asas.',
                    source: ''
                },
                {
                    id: 'tip0181',
                    name: 'Jarro con Asa',
                    definition: 'Vasijas, generalmente de cerámica, vidrio, metal o plástico, que tienen una boca ancha con un labio amplio y generalmente un asa lateral, pero que algunas veces tienen dos orejas. Incluye aquellas que van acompañadas de un lavatorio y se usan para la higiene personal. (Definición: Tesauro Arte y Arquitectura)',
                    source: ''
                },
                {
                    id: 'tip0182',
                    name: 'Jaula',
                    definition: 'Contenedores en forma de caja caladas con barras, alambres o similares usados para confinar su ocupante o para recibir luz exterior o ventilación. TA&A',
                    source: ''
                },
                {
                    id: 'tip0183',
                    name: 'Juego de Cubiertos',
                    definition: 'Juego compuesto por cuchara, tenedor y cuchillo. (Definición: DRAE)',
                    source: ''
                },
                {
                    id: 'tip0184',
                    name: 'Juguete',
                    definition: 'Objeto con el que los niños juegan y desarrollan determinadas capacidades.',
                    source: ''
                },
                {
                    id: 'tip0185',
                    name: 'Letrero',
                    definition: 'Aviso, generalmente decorativo o pictórico, para anunciar, promocionar o publicitar una actividad, causa, producto, o servicio.',
                    source: ''
                },
                {
                    id: 'tip0186',
                    name: 'Librería',
                    definition: 'Mueble con estantes para colocar libros.',
                    source: ''
                },
                {
                    id: 'tip0187',
                    name: 'Lienzo',
                    definition: 'Textil a telar apretado fabricado en diferentes pesos, habitualmente de flax, cáñamo, yute o algodón, utilizado fundamentalmente para confeccionar velas de barco, tela encerada, toldos, tapicería de muebles y como base para la pintura al óleo.',
                    source: ''
                },
                {
                    id: 'tip0188',
                    name: 'Lingote',
                    definition: 'Masa de cualquier metal vaciada dentro de una forma conveniente para su almacenamiento y transporte, para ser enrollada, forjada, fundida o procesada en cualquier otra forma más tarde.',
                    source: ''
                },
                {
                    id: 'tip0189',
                    name: 'Linterna',
                    definition: 'Farol portátil con una sola cara de vidrio y un asa en la opuesta.',
                    source: ''
                },
                {
                    id: 'tip0190',
                    name: 'Llave',
                    definition: 'Instrumento, comúnmente metálico, que, introducido en una cerradura, permite activar el mecanismo que la abre y la cierra.',
                    source: ''
                },
                {
                    id: 'tip0191',
                    name: 'Llave Inglesa',
                    definition: 'Herramienta que sirve para apretar o aflojar tuercas.',
                    source: ''
                },
                {
                    id: 'tip0192',
                    name: 'Llave Regulable',
                    definition: 'Herramienta que sirve para apretar o aflojar tuercas.',
                    source: ''
                },
                {
                    id: 'tip0193',
                    name: 'Llave de Bureta',
                    definition: 'Llave que se intercala en una tubería para cerrar, abrir o regular el curso de un fluido. (DRAE)',
                    source: ''
                },
                {
                    id: 'tip0194',
                    name: 'Llave de Corona',
                    definition: 'Herramienta en forma de horquilla, que sirve para apretar o aflojar las tuercas en los tornillos.',
                    source: ''
                },
                {
                    id: 'tip0195',
                    name: 'Llave de Punta',
                    definition: 'Herramienta cuya utilidad es aflojar o apretar tuercas y/o tornillos.',
                    source: ''
                },
                {
                    id: 'tip0196',
                    name: 'Llave de Punta-Punta',
                    definition: 'Son llaves fabricadas en acero, cuya utilidad es aflojar o apretar tuercas.',
                    source: ''
                },
                {
                    id: 'tip0197',
                    name: 'Lubricador',
                    definition: 'Recipiente con lubricante que sirve para aplicar a algo una sustancia que disminuya la fricción entre superficies en contacto.',
                    source: ''
                },
                {
                    id: 'tip0198',
                    name: 'Lámpara de gas',
                    definition: 'Utensilio para dar luz, que consta de uno mecheros con una boquilla en que se quema un gas.',
                    source: ''
                },
                {
                    id: 'tip0199',
                    name: 'Maceta',
                    definition: 'Recipiente, generalmente de barro cocido, que suele tener un agujero en la parte inferior, y que, lleno de tierra, sirve para cultivar plantas.',
                    source: ''
                },
                {
                    id: 'tip0200',
                    name: 'Manchon de Acople',
                    definition: 'Dispositivo utilizado para unir entre sí dos piezas o cuerpos de modo que ajusten exactamente.',
                    source: ''
                },
                {
                    id: 'tip0201',
                    name: 'Mandril',
                    definition: 'Vástago de madera, metal, etc., que, introducido en ciertos instrumentos huecos, sirve para facilitar la penetración de estos en determinadas cavidades.',
                    source: ''
                },
                {
                    id: 'tip0202',
                    name: 'Manual',
                    definition: 'Libro en que se compendia lo más sustancial de una materia.',
                    source: ''
                },
                {
                    id: 'tip0203',
                    name: 'Manómetro',
                    definition: 'Instrumentos mecánicos de medición para presión que emplean como elemento sensor un tubo de metal curvo o torcido, aplanado en sección transversal y cerrado.',
                    source: ''
                },
                {
                    id: 'tip0204',
                    name: 'Mapa de Chile',
                    definition: 'Se refiere a representaciones gráficas o fotogramétricas de la superficie de la tierra o de una parte de ella, incluyendo características físicas y fronteras políticas, donde cada punto corresponde a una posición geográfica o celestial según una escala o proyección definidas. Los mapas se representan comúnmente en un medio plano, como en papel, una muralla o una pantalla de computador.',
                    source: ''
                },
                {
                    id: 'tip0205',
                    name: 'Mascarilla',
                    definition: 'Máscara que cubre la boca y la nariz para proteger al que respira, o a quien está en su proximidad, de posibles agentes patógenos o tóxicos.',
                    source: ''
                },
                {
                    id: 'tip0206',
                    name: 'Matraz',
                    definition: 'Recipiente de vidrio, generalmente de forma esférica y con un cuello recto y estrecho, que se usa en los laboratorios para contener y medir líquidos.',
                    source: ''
                },
                {
                    id: 'tip0207',
                    name: 'Matraz Balón de fondo plano',
                    definition: 'El matraz de balón, también conocido como balón de destilación, es un instrumento de laboratorio que consiste en un recipiente de vidrio con el cuello alargado y delgado, dotado con un tubo lateral delgado (desembocadura), su parte inferior es en forma redondeada y de fondo plano.',
                    source: ''
                },
                {
                    id: 'tip0208',
                    name: 'Matraz de Erlenmeyer',
                    definition: 'Vasijas globulares aplanadas o en forma de pera con un cuello corto y angosto y una pequeña boca, generalmente de vidrio, cerámica o metal y usadas para diferentes propósitos. También para vasijas de cuello angosto, generalmente de vidrio, de cuerpo redondeado, usadas especialmente en laboratorios. TA&A',
                    source: ''
                },
                {
                    id: 'tip0209',
                    name: 'Mazo',
                    definition: 'Garrotes usados en toda Europa principalmente desde el siglo XI hasta el siglo XVII, hechos enteramente en metal o consistentes en un asta de madera y una cabeza de metal que tiene ranuras o pestañas horizontales y algunas veces rematando en una punta.',
                    source: ''
                },
                {
                    id: 'tip0210',
                    name: 'Megáfono',
                    definition: 'Artefactos huecos usados para intensificar y dirigir el sonido de una voz, generalmente, aunque no siempre, tomando la forma de un cono largo con un pequeño agujero en uno de los extremos a través del cual el usuario habla, y un abertura mucho más amplia en el otro desde donde el sonido sale.',
                    source: ''
                },
                {
                    id: 'tip0211',
                    name: 'Mesa',
                    definition: 'Mueble compuesto de un tablero horizontal liso y sostenido a la altura conveniente, generalmente por una o varias patas, para diferentes usos, como escribir, comer, etc. RAE',
                    source: ''
                },
                {
                    id: 'tip0212',
                    name: 'Mira telescópica',
                    definition: 'Sistema óptico, cuya función es aumentar la imagen de manera nítida del objetivo enfocado, facilitando la precisión y exactitud (puntería) y así aumentar las posibilidades de acertar disparos a larga distancia con un arma. Wikipedia',
                    source: ''
                },
                {
                    id: 'tip0213',
                    name: 'Mira topográfica',
                    definition: 'Sistema óptico, cuya función es aumentar la imagen de manera nítida del objetivo enfocado, facilitando la precisión y exactitud (puntería) y así aumentar las posibilidades de acertar disparos a larga distancia con un arma. Wikipedia',
                    source: ''
                },
                {
                    id: 'tip0214',
                    name: 'Mochila',
                    definition: 'Bolsa de lona o de otro material resistente que, provista de correas para ser cargada a la espalda, sirve para llevar provisiones o equipos en excursiones, expediciones, viajes, etc.',
                    source: ''
                },
                {
                    id: 'tip0215',
                    name: 'Molde de muestras',
                    definition: 'Pieza en que se hace en hueco la forma que quiere darse en sólido a la materia que se vacía en él : un metal , un plástico , etc. DRAE',
                    source: ''
                },
                {
                    id: 'tip0216',
                    name: 'Molino de Bolas',
                    definition: 'Es un equipo con cuerpo cilíndrico cónico o tambor que gira entorno a su eje horizontal, se encuentra parcialmente lleno con bolas de hierro o acero. Los espacios entre las bolas son ocupados con la carga a ser molida. Siguiendo la rotación del tambor, las bolas se elevan junto a la pared cilíndrica del molino y descienden en forma de cascada desde cierta altura, como resultado el material cargado es molido producto del impacto de dos bolas. Los molinos de bolas pueden operar en seco o con una solución de agua. Se utilizan principalmente en la industria minera.',
                    source: ''
                },
                {
                    id: 'tip0217',
                    name: 'Mortero',
                    definition: 'Utensilio de cocina o laboratorio que sirve para moler o machacar especias, semillas, sustancias químicas, etc.; consiste en un recipiente de piedra, cerámica, madera u otro material con forma de vaso ancho de cavidad semiesférica y un pequeño mazo (mano de mortero) con el que se machaca.',
                    source: ''
                },
                {
                    id: 'tip0218',
                    name: 'Mortero Grande',
                    definition: 'Utensilio de cocina o laboratorio que sirve para moler o machacar especias, semillas, sustancias químicas, etc.; consiste en un recipiente de piedra, cerámica, madera u otro material con forma de vaso ancho de cavidad semiesférica y un pequeño mazo (mano de mortero) con el que se machaca.',
                    source: ''
                },
                {
                    id: 'tip0219',
                    name: 'Mostrador',
                    definition: 'Originalmente mesas pequeñas o arcones con la parte superior marcada para contar dinero, algunas veces con un aparador debajo. Término más tarde aplicado a cajas o superficies semejantes a estantes normalmente de una altura adecuada para que una persona se pare delante de ellos, como en una cocina o tienda, o se siente en taburetes frente a ellos, como en un restaurante o un bar.',
                    source: ''
                },
                {
                    id: 'tip0220',
                    name: 'Motor Diesel',
                    definition: 'Motor de combustión interna cuya característica más relevante es la ausencia de chispa que encienda la mezcla.',
                    source: ''
                },
                {
                    id: 'tip0221',
                    name: 'Motor Eléctrico',
                    definition: 'Máquinas que transforman la energía eléctrica en energía mecánica de rotación.',
                    source: ''
                },
                {
                    id: 'tip0222',
                    name: 'Muestra de Mineral',
                    definition: 'Parte o porción representativa de extracción mineral',
                    source: ''
                },
                {
                    id: 'tip0223',
                    name: 'Muleta',
                    definition: 'Bastones o apoyos para asistir a un discapacitado o enfermo para caminar, especialmente aquellos con un travesaño en un extremo para encajar debajo de la axila.',
                    source: ''
                },
                {
                    id: 'tip0224',
                    name: 'O-Ring',
                    definition: 'Junta tórica de forma toroidal, habitualmente de goma, cuya función es la de asegurar la estanqueidad de fluidos, en cilindros hidráulicos y cilindros neumáticos.',
                    source: ''
                },
                {
                    id: 'tip0225',
                    name: 'Ofrendero',
                    definition: 'Caja de madera u otra materia, con cerradura y una abertura por la que se introducen las limosnas, que se fija en las iglesias y otros lugares.',
                    source: ''
                },
                {
                    id: 'tip0226',
                    name: 'Olla',
                    definition: 'La olla es uno de los recipientes que presenta mayores variaciones de forma y materialidad, pero su funcionalidad, que es la de preparar alimentos, es transversal y puede ser el factor indicador en su identificación. Su base especialmente diseñada para ser expuesta al fuego, es redondeada e incluso apuntada en ollas arqueológicas e históricas, mientras que se vuelve plana en objetos más contemporáneos; su cuerpo puede ser cilíndrico, globular y también asimétrico, y puede o no presentar asas y cuello. En ocasiones posee tapadera y patas.',
                    source: ''
                },
                {
                    id: 'tip0227',
                    name: 'Organizador',
                    definition: 'Marcos de alambre, estacas, barras, rejillas o estantes en que artículos se colocan o se suspenden, a menudo para almacenamiento o estiba; puede ser independiente o pegado a las superficies.',
                    source: ''
                },
                {
                    id: 'tip0228',
                    name: 'PLano de estructura',
                    definition: 'Se refiere a planos que ilustran las vigas y la viga maestra de cada piso de un edificio y como estos se conectan. Estos usualmente emplean un sistema simple de símbolos y de líneas de borrados más que un dibujo detallado.',
                    source: ''
                },
                {
                    id: 'tip0229',
                    name: 'Paila',
                    definition: 'Una paila es una sartén de metal o de cerámica, grande, redonda y poco profunda.',
                    source: ''
                },
                {
                    id: 'tip0230',
                    name: 'Pala',
                    definition: 'Herramientas que constan de una amplia lámina o pala sujeta a un mango largo, utilizadas para tomar objetos para montaje y desmontaje, o tirar material como tierra, nieve o carbón.',
                    source: ''
                },
                {
                    id: 'tip0231',
                    name: 'Palangana',
                    definition: 'Bol o cuenco grande para lavarse las manos y la cara, por lo general acompañado de una jarra.',
                    source: ''
                },
                {
                    id: 'tip0232',
                    name: 'Paleta ping pong',
                    definition: 'Pala de madera pequeña que se usa en el deporte del ping pong.',
                    source: ''
                },
                {
                    id: 'tip0233',
                    name: 'Panel de distribución',
                    definition: 'Panel o grupo de paneles diseñados para ensamblarse en forma de un solo panel; incluyendo las barras conductoras, los dispositivos automáticos de protección contra sobrecorriente; y está equipado con o sin interruptores para el control de circuitos de alumbrado, calefacción o potencia; está diseñado para ser instalado en un gabinete o cubierta, montado en o contra una pared o división, u otro soporte y accesible sólo por el frente.',
                    source: ''
                },
                {
                    id: 'tip0234',
                    name: 'Papel de filtro',
                    definition: 'Papel absorbente y poroso adecuado para ser utilizado como filtro, fabricado de fibras abultadas y esponjosas como las del álamo, o fibras afieltradas flojas.',
                    source: ''
                },
                {
                    id: 'tip0235',
                    name: 'Pasta de dientes',
                    definition: 'Dicho de polvos, pastas, aguas, etc.: Que se usan para limpiar y mantener sana la dentadura. U. t. c. s. m.',
                    source: ''
                },
                {
                    id: 'tip0236',
                    name: 'Peineta',
                    definition: 'Instrumento con una hilera de dientes en uno o en ambos de sus bordes o lados, que se utiliza para ajustar, escarmenar o limpiar el pelo.',
                    source: ''
                },
                {
                    id: 'tip0237',
                    name: 'Peluche',
                    definition: 'Úsese para juguetes, a menudo representaciones de animales o muñecas, rellenos y cubiertos con materiales suaves.',
                    source: ''
                },
                {
                    id: 'tip0238',
                    name: 'Pesebre',
                    definition: 'Representación del nacimiento de Jesucristo, que se hace en época navideña en los países de tradición católica.',
                    source: ''
                },
                {
                    id: 'tip0239',
                    name: 'Pinza',
                    definition: 'Cualquiera de varias pequeñas herramientas en forma de pinza, utilizadas para arrancar, sujetar o manipular. TA&A.',
                    source: ''
                },
                {
                    id: 'tip0240',
                    name: 'Pinza para bureta doble',
                    definition: 'Herramienta de metal que se une al soporte universal para sujetar verticalmente al menos una bureta.',
                    source: ''
                },
                {
                    id: 'tip0241',
                    name: 'Pinza para crisol',
                    definition: 'Herramientas en forma de pinza, utilizadas para arrancar, sujetar o manipular. Las pinzas para crisoles tienen forma de tenazas, o de tijeras grandes con el extremo adaptado para sujetar un crisol mientras se calienta fuertemente. Solo pueden estar construidas en metal, para aguantar temperaturas muy altas, y se necesitan guantes protectores para agarrarlas.',
                    source: ''
                },
                {
                    id: 'tip0242',
                    name: 'Pipeta',
                    definition: 'Tubo delgado, usualmente de vidrio, de pequeño calibre y que se usa para obtener un volumen pequeño de líquido, especialmente en trabajo de laboratorio; a menudo incorporando una reserva central ensanchada.',
                    source: ''
                },
                {
                    id: 'tip0243',
                    name: 'Pipeta (fragmento)',
                    definition: 'Tubo delgado, usualmente de vidrio, de pequeño calibre y que se usa para obtener un volumen pequeño de líquido, especialmente en trabajo de laboratorio; a menudo incorporando una reserva central ensanchada. TA&A',
                    source: ''
                },
                {
                    id: 'tip0244',
                    name: 'Pistón',
                    definition: 'Pieza que se mueve alternativamente en el interior de un cuerpo de bomba o del cilindro de una máquina para enrarecer o comprimir un fluido o recibir de él movimiento.',
                    source: ''
                },
                {
                    id: 'tip0245',
                    name: 'Pistón completo',
                    definition: 'Pieza que se mueve alternativamente en el interior de un cuerpo de bomba o del cilindro de una máquina para enrarecer o comprimir un fluido o recibir de él movimiento.',
                    source: ''
                },
                {
                    id: 'tip0246',
                    name: 'Pizarra',
                    definition: 'Superficie de escritura reutilizable en la cual el texto o figuras se realizan con tiza u otro tipo de rotuladores borrables.',
                    source: ''
                },
                {
                    id: 'tip0247',
                    name: 'Placa',
                    definition: 'Pieza plana, de diferentes tipos de materias (cerámica, arcilla, metal, marfil, yeso, etc.) alargada y poco espesa pero rígida. Suele llevar una inscripción, una decoración grabada o una decoración en relieve (motivos geométricos, vegetales, animales, paganos o cristianos). La pieza puede ser aplicada o no sobre un edificio o un monumento.',
                    source: ''
                },
                {
                    id: 'tip0248',
                    name: 'Placa de porcelana - Portamuestras (laboratorio)',
                    definition: 'Dispositivo soporte que permite y facilita el análisis de una muestra. (diccionario.raing.es)',
                    source: ''
                },
                {
                    id: 'tip0249',
                    name: 'Placa torneada',
                    definition: 'Concepto general para láminas o discos relativamente delgados de material rígido como metal o vidrio y que tienen una superficie y un grosor uniformes, que se usan para realizar trabajos en serie. TA&A',
                    source: ''
                },
                {
                    id: 'tip0250',
                    name: 'Plancha',
                    definition: 'Utensilio de forma triangular, con base metálica lisa y pulida y un asa en la parte superior, que se calienta normalmente con energía eléctrica y sirve para planchar.',
                    source: ''
                },
                {
                    id: 'tip0251',
                    name: 'Plantilla zapato',
                    definition: 'Suelas de zapatos sobre el cual el pie se apoya, por lo general cubierto o parcialmente cubierto por la capa media.',
                    source: ''
                },
                {
                    id: 'tip0252',
                    name: 'Plato',
                    definition: 'Recipiente bajo y generalmente redondo, con una concavidad en medio y borde comúnmente plano alrededor, empleado para servir alimentos y comer en él y para otros usos.',
                    source: ''
                },
                {
                    id: 'tip0253',
                    name: 'Plato metálico',
                    definition: 'Recipiente bajo y generalmente redondo, con una concavidad en medio y borde comúnmente plano alrededor, empleado para servir los alimentos y comer en él y para otros usos. DRAE',
                    source: ''
                },
                {
                    id: 'tip0254',
                    name: 'Platos',
                    definition: 'Recipiente bajo y generalmente redondo, con una concavidad en medio y borde comúnmente plano alrededor, empleado para servir los alimentos y comer en él y para otros usos. DRAE',
                    source: ''
                },
                {
                    id: 'tip0255',
                    name: 'Polaina Descarne',
                    definition: 'Cubierta de tela, cuero o materiales similares que se usa sobre el tobillo y a veces la parte inferior de la pierna.',
                    source: ''
                },
                {
                    id: 'tip0256',
                    name: 'Polines',
                    definition: 'Son los elementos encargados de soportar la cinta transportadora y su carga. Se componen de una estructura base y de uno o más rodillos sobre los que se apoya la cinta.',
                    source: ''
                },
                {
                    id: 'tip0257',
                    name: 'Porta resorte',
                    definition: 'Elemento de protección de un resorte.',
                    source: ''
                },
                {
                    id: 'tip0258',
                    name: 'Portabaterías',
                    definition: 'Dispositivo contenedor de pilas y/o baterías el cual resguarda a la pila y/o batería y permite una conexión segura y prolija.',
                    source: ''
                },
                {
                    id: 'tip0259',
                    name: 'Portarretrato',
                    definition: 'Marco portátil de fotografías con un pie al dorso sobre el que se apoya para mantenerse levantado.',
                    source: ''
                },
                {
                    id: 'tip0260',
                    name: 'Portería',
                    definition: 'En el juego del fútbol y otros semejantes, marco rectangular formado por dos postes y un larguero, por el cual ha de entrar el balón o la pelota para marcar tantos.',
                    source: ''
                },
                {
                    id: 'tip0261',
                    name: 'Probeta',
                    definition: 'Vasijas cilíndricas de fondo plano generalmente con pico de vertido, especialmente aquellos usados por químicos y farmacéuticos.',
                    source: ''
                },
                {
                    id: 'tip0262',
                    name: 'Pupitre',
                    definition: 'Mesa de escuela que suele tener algún compartimento en el que guardar el material escolar.',
                    source: ''
                },
                {
                    id: 'tip0263',
                    name: 'Póster',
                    definition: 'Cartel que se fija en la pared sin finalidad publicitaria o habiendo perdido ese carácter.',
                    source: ''
                },
                {
                    id: 'tip0264',
                    name: 'Quemador de fierro',
                    definition: 'Instrumento que se utiliza para grabar una marca con un código en los animales de granja.',
                    source: ''
                },
                {
                    id: 'tip0265',
                    name: 'Radio',
                    definition: 'Aparato empleado en radiotelegrafía y radiotelefonía para recoger y transformar en señales las ondas emitidas por el radiotransmisor.',
                    source: ''
                },
                {
                    id: 'tip0266',
                    name: 'Radiocasete',
                    definition: 'Aparato electrónico que consta de una radio y un casete.',
                    source: ''
                },
                {
                    id: 'tip0267',
                    name: 'Rallador',
                    definition: 'Utensilio de cocina, compuesto principalmente de una chapa de metal, curva y llena de agujerillos de borde saliente, que sirve para desmenuzar el pan, el queso, etc., restregándolos con él.',
                    source: ''
                },
                {
                    id: 'tip0268',
                    name: 'Recipiente',
                    definition: 'Utensilio destinado a guardar o conservar algo. Cualquier objeto o utensilio cóncavo que sirve para contener una cosa en su interior.',
                    source: ''
                },
                {
                    id: 'tip0269',
                    name: 'Recipiente cobre con objetos',
                    definition: 'Utensilio destinado a guardar o conservar algo.',
                    source: ''
                },
                {
                    id: 'tip0270',
                    name: 'Recipiente con aras para muestras',
                    definition: 'Recipiente metálico utilizado para tomar muestras en la minería',
                    source: ''
                },
                {
                    id: 'tip0271',
                    name: 'Recipiente metálico',
                    definition: 'Objeto para contener o guardar algo, es todo receptáculo destinado a albergar en su interior hueco productos sólidos (o semisólidos, como polvos, gránulos), líquidos o gases, y usado en numerosos ámbitos de la vida cotidiana, el comercio y la industria.',
                    source: ''
                },
                {
                    id: 'tip0272',
                    name: 'Reloj',
                    definition: 'Instrumento que mide e indica el paso del tiempo.',
                    source: ''
                },
                {
                    id: 'tip0273',
                    name: 'Repuesto copa reflector',
                    definition: 'Objeto que tiene como fin capturar la mayor parte posible del flujo luminoso emitido por diversas fuentes de luz.',
                    source: ''
                },
                {
                    id: 'tip0274',
                    name: 'Repuesto goma para linterna',
                    definition: 'Objeto de goma de forma circular utilizado como repuesto para linternas.',
                    source: ''
                },
                {
                    id: 'tip0275',
                    name: 'Repuesto para linterna',
                    definition: 'Vidrio incoloro de forma circular utilizado como repuesto para linternas',
                    source: ''
                },
                {
                    id: 'tip0276',
                    name: 'Repuesto placa para válvula',
                    definition: 'Repuesto de placas para válvulas de compresión.',
                    source: ''
                },
                {
                    id: 'tip0277',
                    name: 'Resorte',
                    definition: 'Cuerpos elásticos, a menudo tiras o alambres de acero enrollado espiralmente, que recuperan su forma después de ser comprimidos, doblados o estirados.',
                    source: ''
                },
                {
                    id: 'tip0278',
                    name: 'Revista',
                    definition: 'Publicación periódica con textos e imágenes sobre varias materias, o sobre una especialmente.',
                    source: ''
                },
                {
                    id: 'tip0279',
                    name: 'Rodillo',
                    definition: 'Cilindro muy pesado de piedra o de hierro, que se hace rodar para allanar y apretar la tierra o para consolidar el firme de las carreteras.',
                    source: ''
                },
                {
                    id: 'tip0280',
                    name: 'Rosario',
                    definition: 'Sarta de cuentas, separadas de diez en diez por otras de distinto tamaño, unida por sus dos extremos a una cruz, precedida por lo común de tres cuentas pequeñas, que suele adornarse con medallas u otros objetos de devoción y sirve para hacer ordenadamente el rezo del mismo nombre o una de sus partes.',
                    source: ''
                },
                {
                    id: 'tip0281',
                    name: 'Rotor',
                    definition: 'Es un componente de generadores y motores eléctricos, conformado por un eje que soporta la bobinas.',
                    source: ''
                },
                {
                    id: 'tip0282',
                    name: 'Saco',
                    definition: 'Receptáculo de tela, cuero, papel, etc., por lo común de forma rectangular o cilíndrica, abierto por uno de los lados.',
                    source: ''
                },
                {
                    id: 'tip0283',
                    name: 'Sagrario',
                    definition: 'Lugar donde se guarda y deposita a Cristo sacramentado.',
                    source: ''
                },
                {
                    id: 'tip0284',
                    name: 'Salamandra',
                    definition: 'Especie de calorífero de combustión lenta. RAE',
                    source: ''
                },
                {
                    id: 'tip0285',
                    name: 'Sartén',
                    definition: 'Cacerolas metálicas de poca profundidad con un solo mango relativamente largo, usadas para freír alimentos: algunas veces provistas de una tapa.',
                    source: ''
                },
                {
                    id: 'tip0286',
                    name: 'Sello de aceite',
                    definition: 'Pieza que se utiliza para evitar fugas o el intercambio no deseado de fluidos, gases o sólidos desde un elemento que los contiene al adyacente.',
                    source: ''
                },
                {
                    id: 'tip0287',
                    name: 'Separador de Muestras',
                    definition: 'Herramienta utilizada para dividir el material tamizado en muestras representativas del ensayo.',
                    source: ''
                },
                {
                    id: 'tip0288',
                    name: 'Serrucho',
                    definition: 'Nombre dado a un grupo de sierras que tienen un mango en un extremo y un conector ancho que se estrecha en anchura, son sierras de mano, no son sierras mecánicas. TA&A',
                    source: ''
                },
                {
                    id: 'tip0289',
                    name: 'Set de Escariadores',
                    definition: 'Los escariadores son herramientas utilizadas en la actividad de sondaje, esta permite mantener el diámetro de la perforación a lo largo del tiro',
                    source: 'Glosario Minero'
                },
                {
                    id: 'tip0290',
                    name: 'Set de coronas',
                    definition: 'Conjunto de herramientas de corte que pueden llevar diamantes naturales o sintéticos, incrustados o impregnados en una matriz de aleación con tungsteno.',
                    source: ''
                },
                {
                    id: 'tip0291',
                    name: 'Señalética',
                    definition: 'Conjunto de señales para informar de algo.',
                    source: ''
                },
                {
                    id: 'tip0292',
                    name: 'Sierra Eléctrica',
                    definition: 'Herramienta para cortar madera, piedra u otros objetos duros, que generalmente consiste en una hoja de acero dentada sujeta a una empuñadura, bastidor o armazón.',
                    source: ''
                },
                {
                    id: 'tip0293',
                    name: 'Silla',
                    definition: 'Asientos para una persona con respaldo y brazos preferencialmente. Distinguir de “taburetes” que no tienen respaldo.',
                    source: ''
                },
                {
                    id: 'tip0294',
                    name: 'Sistema Modular de Carga MiniSpot',
                    definition: 'Bastidor modular automático de carga para Lámparas Modelo ML-2 sirve para 60, 40, 10 ó 6 lámparas.',
                    source: ''
                },
                {
                    id: 'tip0295',
                    name: 'Sobre',
                    definition: 'Estuches rectangulares y planos hechos de material flexible que se doblan resultando dobleces superpuestos que se pegan juntos en dos o tres bordes; a menudo con cierre de bandera y usados para pequeños objetos o documentos. TA& A',
                    source: ''
                },
                {
                    id: 'tip0296',
                    name: 'Sombrero de lluvia',
                    definition: 'Cubierta de forma definida para la cabeza que tiene alas y corona o copa, o una de las dos.',
                    source: ''
                },
                {
                    id: 'tip0297',
                    name: 'Soporte para contrapesos de balanza antigua',
                    definition: 'Apoyo o sostén de contrapesos.',
                    source: ''
                },
                {
                    id: 'tip0298',
                    name: 'Stoper',
                    definition: 'Es una maquina neumática que sirve para la realización de taladros que varían de (30-45mm de diámetro) para trabajo horizontal o al piso.',
                    source: ''
                },
                {
                    id: 'tip0299',
                    name: 'Suéter corto',
                    definition: 'Prenda de punto o hecha a croché que se usa en la parte superior del cuerpo y que se extiende hasta la cintura o bajo ella. TA&A',
                    source: ''
                },
                {
                    id: 'tip0300',
                    name: 'Tablero de ajedrez',
                    definition: 'Tableros divididos en 64 cuadrados de colores alternativamente oscuros y claros organizados ocho verticalmente por ocho horizontalmente; idéntico al tablero de damas. TA&A',
                    source: ''
                },
                {
                    id: 'tip0301',
                    name: 'Tablero eléctrico',
                    definition: 'Componente principal de una instalación eléctrica, en él se protegen cada uno de los distintos circuitos en los que se divide la instalación eléctrica a través de fusibles, protecciones magnetotérmicas y diferenciales.',
                    source: ''
                },
                {
                    id: 'tip0302',
                    name: 'Taburete de madera',
                    definition: 'Asiento individual, sin respaldo ni brazos, de pie abierto (tres, cuatro o más pies), o cerrado.',
                    source: ''
                },
                {
                    id: 'tip0303',
                    name: 'Taladro Berbiquí antiguo',
                    definition: 'El berbiquí es una herramienta manual utilizada en ebanistería y carpintería para hacer agujeros en maderas.',
                    source: ''
                },
                {
                    id: 'tip0304',
                    name: 'Talonario',
                    definition: 'Cuaderno o libro que contiene libranzas, recibos, billetes u otros documentos, de los cuales, cuando se cortan, queda una parte como comprobante.',
                    source: ''
                },
                {
                    id: 'tip0305',
                    name: 'Tamiz',
                    definition: 'Utensilio perforado o enrejado, o herramienta a través de la cual materiales sueltos y secos (como harina) son tamizados o refinados; materiales que contienen líquidos son filtrados; o sólidos blandos (como huevos duros) son triturados empleando la fuerza (como con un molinillo)',
                    source: ''
                },
                {
                    id: 'tip0306',
                    name: 'Tampón entintador',
                    definition: 'Herramienta utilizada para entintar una placa o superficie en la base del grabado, por lo general una almohadilla de algodón cubierta con seda o cuero, a veces una pieza de material enrollado fieltro u otro.',
                    source: ''
                },
                {
                    id: 'tip0307',
                    name: 'Tapa de caja para Empalme',
                    definition: 'Las Cajas de Empalme se utilizan en la distribución y derivación de cables en montajes eléctricos de sistemas residenciales, industriales y comerciales.',
                    source: ''
                },
                {
                    id: 'tip0308',
                    name: 'Tapón',
                    definition: 'Cierres diseñados para tapar una abertura. (TA&A)',
                    source: ''
                },
                {
                    id: 'tip0309',
                    name: 'Tarjetero',
                    definition: 'Caja abierta o bandeja en la que se dejan las tarjetas de visita.',
                    source: ''
                },
                {
                    id: 'tip0310',
                    name: 'Taza',
                    definition: 'Recipiente que sirve para beber líquidos, en especial bebidas calientes, como café o té; forma parte del servicio de mesa, es más ancho que alto, generalmente más ancho en la boca que en la base, y está provisto de un asa.',
                    source: ''
                },
                {
                    id: 'tip0311',
                    name: 'Tee',
                    definition: 'Es un accesorio para tubos en forma de “T” que tiene tres bocas o ramales. Normalmente se clasifica en dos formas: Tee Recta y Tee Reducida (o Reductor Tee). Ambas se utilizan para dividir (combinar) las tuberías y ajustar el flujo o cambiar la dirección.',
                    source: 'www.octalacero.com'
                },
                {
                    id: 'tip0312',
                    name: 'Tejido',
                    definition: 'Término genérico para designar cualquier producto textil plano obtenido por el entrecruzamiento o por la ligazón de hilos o de fibras textiles. Los tejidos, a parte de su uso en la indumentaria y en diversas artesanías, fueron uno de los principales soportes de las técnicas pictóricas',
                    source: ''
                },
                {
                    id: 'tip0313',
                    name: 'Tejuela',
                    definition: 'Pedazo de teja de madera.',
                    source: ''
                },
                {
                    id: 'tip0314',
                    name: 'Televisor',
                    definition: 'Dispositivos que convierten las señales de entrada de televisión en imágenes originales y sus sonidos asociados.',
                    source: ''
                },
                {
                    id: 'tip0315',
                    name: 'Teléfono',
                    definition: 'Conjunto de aparatos e hilos conductores con los cuales se transmite a distancia la palabra y toda clase de sonidos por la acción de la electricidad.',
                    source: ''
                },
                {
                    id: 'tip0316',
                    name: 'Tenedor',
                    definition: 'Artículos de cubiertos que tiene un mango y una pieza que termina en dos o más dientes, se utiliza para manipular alimentos, especialmente en el servicio y alimentación. TA&A',
                    source: ''
                },
                {
                    id: 'tip0317',
                    name: 'Termo Eléctrico',
                    definition: 'Dispositivo termodinámico que emplea energía eléctrica para calentar y almacenar agua',
                    source: 'https://www.construmatica.com/'
                },
                {
                    id: 'tip0318',
                    name: 'Testigo de perforación',
                    definition: 'Muestra cilíndrica de roca tomada del fondo de un pozo o de una roca por medio de un barreno durante el proceso de perforación.',
                    source: ''
                },
                {
                    id: 'tip0319',
                    name: 'Tetera',
                    definition: 'Vasijas cubiertas para preparar y servir té, generalmente con un pico, el cual es a menudo largo y graciosamente curvado y, en el lado opuesto de la vasija, un asa y algunas veces pequeños pies. Se diferencia de "jarra de té", que generalmente tiene un pico corto vertedor, un asa de barra y una base ancha y plana, y se usa para hervir el agua con la cual el té se prepara.',
                    source: ''
                },
                {
                    id: 'tip0320',
                    name: 'Textil',
                    definition: 'Término genérico empleado para designar tanto a las fibras textiles, naturales o sintéticas, susceptibles a formar productos textiles (como hilos, cuerdas, tejidos, telas no tejidas, etc.), como a estos mismos productos.',
                    source: ''
                },
                {
                    id: 'tip0321',
                    name: 'Tijera',
                    definition: 'Instrumento compuesto de dos hojas de acero, a manera de cuchillas de un solo filo, y por lo común con un ojo para meter los dedos al remate de cada mango, las cuales pueden girar alrededor de un eje que las traba, para cortar, al cerrarlas, lo que se pone entre ellas.',
                    source: ''
                },
                {
                    id: 'tip0322',
                    name: 'Timbre',
                    definition: 'Objeto destinado a entintar papel.',
                    source: ''
                },
                {
                    id: 'tip0323',
                    name: 'Tiraje',
                    definition: 'Tiro de la chimenea.',
                    source: ''
                },
                {
                    id: 'tip0324',
                    name: 'Tobogán',
                    definition: 'Rampa deslizante, generalmente con bordes, por la que las personas, sentadas o tendidas, se dejan resbalar por diversión.',
                    source: ''
                },
                {
                    id: 'tip0325',
                    name: 'Tocadiscos',
                    definition: 'Aparato que consta de un platillo giratorio, sobre el que se colocan los discos musicales o sonoros y de un fonocaptor.',
                    source: ''
                },
                {
                    id: 'tip0326',
                    name: 'Tope',
                    definition: 'Pieza o elemento destinado a detener un movimiento o amortiguar un choque.',
                    source: ''
                },
                {
                    id: 'tip0327',
                    name: 'Tope de Puerta',
                    definition: 'Pieza que sirve para impedir que el movimiento de una puerta pase de un cierto punto',
                    source: ''
                },
                {
                    id: 'tip0328',
                    name: 'Traje impermeable',
                    definition: 'Es un tipo de ropa de agua. Es una gabardina no permeable al agua, principalmente para proteger de la lluvia, hecho de material plastificado o laminado con plástico o -antes de la existencia del plástico- con goma.',
                    source: ''
                },
                {
                    id: 'tip0329',
                    name: 'Trepadora',
                    definition: 'Cada uno de los garfios con dientes interiores que se sujetan con correas, uno a cada pie, y que sirven para subir a los postes del telégrafo y otros análogos.',
                    source: ''
                },
                {
                    id: 'tip0330',
                    name: 'Trituradora',
                    definition: 'Cualquiera de varios artefactos culinarios utilizados para moler comida, como grano, verduras o carne.. TA&A.',
                    source: ''
                },
                {
                    id: 'tip0331',
                    name: 'Tubería',
                    definition: 'Conductos tubulares, generalmente cañerías de escape, para el transporte de líquidos o gases.',
                    source: ''
                },
                {
                    id: 'tip0332',
                    name: 'Tuerca',
                    definition: 'Bloques pequeños, generalmente metálicos, que tienen un espacio hilado en el centro para recibir un cerrojo o una barra hilada.',
                    source: ''
                },
                {
                    id: 'tip0333',
                    name: 'Tuerca',
                    definition: 'Pieza con un hueco labrado en espiral que ajusta exactamente en el filete de un tornillo.',
                    source: ''
                },
                {
                    id: 'tip0334',
                    name: 'Turbina',
                    definition: 'Cualquiera de varias máquinas que convierten la energía cinética de un fluido en movimiento en energía mecánica. A menudo se utiliza para accionar un generador eléctrico.',
                    source: ''
                },
                {
                    id: 'tip0335',
                    name: 'Unidad dental',
                    definition: 'La unidad dental es un conjunto de elementos odontológicos sobre los que el dentista y los higienistas trabajan (www.dvd-dental.com/).',
                    source: ''
                },
                {
                    id: 'tip0336',
                    name: 'Vaso de Precipitado',
                    definition: 'Recipiente cilíndrico de vidrio borosilicatado fino que se utiliza muy comúnmente en el laboratorio, sobre todo, para preparar o calentar sustancias, medir o traspasar líquidos. Wikipedia',
                    source: ''
                },
                {
                    id: 'tip0337',
                    name: 'Vidrio de reloj',
                    definition: 'Lámina de vidrio redondo convexo que permite contener las sustancias para luego masarlas o pesarlas en la balanza. Se denomina vidrio de reloj ya que es muy similar a uno de ellos.',
                    source: ''
                },
                {
                    id: 'tip0338',
                    name: 'Viruta',
                    definition: 'Hoja delgada que se saca con el cepillo u otras herramientas al labrar la madera o los metales, y que sale, por lo común, arrollada en espiral.',
                    source: ''
                },
                {
                    id: 'tip0339',
                    name: 'Válvula de Admisión',
                    definition: 'Mecanismo que regula el flujo de la comunicación entre dos partes de una máquina o sistema.',
                    source: ''
                },
                {
                    id: 'tip0340',
                    name: 'Zapatilla de deporte',
                    definition: 'Calzado hecho generalmente con piel o lona y suela de goma, que se ata con cordones o se sujeta con velcro, y se usa para practicar algunos deportes.',
                    source: ''
                },
                {
                    id: 'tip0341',
                    name: 'Zapato',
                    definition: 'Calzado hecho generalmente con piel o lona y suela de goma, que se ata con cordones o se sujeta con velcro, y se usa para proteger el pie.',
                    source: ''
                },
                {
                    id: 'tip0342',
                    name: 'Zapato Infante',
                    definition: 'Zapato (calzado): Calzado hecho generalmente con piel o lona y suela de goma, que se ata con cordones o se sujeta con velcro, y se usa para practicar algunos deportes, o proteger los pies.',
                    source: ''
                },
                {
                    id: 'tip0343',
                    name: 'estabilizador',
                    definition: 'Que da estabilidad',
                    source: ''
                },
                {
                    id: 'tip0344',
                    name: 'moneda',
                    definition: 'Piezas de metal grabadas por la autoridad gubernamental para usar como dinero.',
                    source: ''
                },
                {
                    id: 'tip0345',
                    name: 'tapa de olla',
                    definition: 'Pieza que cierra por la parte superior una olla.',
                    source: ''
                },
                {
                    id: 'tip0346',
                    name: 'tapaorejas',
                    definition: 'Dispositivo que se utiliza para atenuar el ruido entre los de tipo auricular.',
                    source: ''
                }
            ]
            try {

                await updateDoc(doc(db, 'System', 'tipologias'), {
                    datos: tipologias
                })
            } catch (e) {
                console.log('error', e)
            }



        }
    },
    getters: {
        items: state => {
            return state.items
        },
        abcItems: state => {
            let arr = []
            state.items.forEach(item => {
                let char = item.name.split('')[0]
                let exists = arr.find(el => el === char.toUpperCase())
                if (exists === undefined) {
                    arr.push(char.toUpperCase())
                }
            })
            return arr
        },
        itemsByIndex: state => payload => {
            let arr = []
            state.items.forEach(item => {
                let char = item.name.split('')[0]
                if (char.toUpperCase() === payload) {
                    if (item.definition.split('').length >= 200) {
                        arr.push({
                            ...item,
                            shortDefinition: item.definition.slice(0, 199),
                            isLong: true
                        })
                    } else {
                        arr.push({
                            ...item,
                            shortDefinition: item.definition,
                            isLong: false
                        })
                    }
                }
            })
            return arr
        }
    },
    mutations: {
        SET_ITEMS(state, payload) {
            state.items = payload.list
        },
        ADD_ITEMS(state, payload) {
            state.items.push(payload)
        },
        CLEAR_ITEMS(state) {
            state.items = []
        },
        REMOVE_ITEM_BY_ID(state, payload) {
            let index = state.items.findIndex(item => item.id === payload)
            if (index >= 0) {
                state.items.splice(index, 1)
            }
        },
        UPDATE_ITEM_NAME(state, payload) {
            let elem = state.items.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.name
            }
        },
        UPDATE_ITEM_DEFINITION(state, payload) {
            let elem = state.items.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.definition
            }
        },
        SET_ITEM_OBJECTS(state, payload) {
            let result = state.items.find(elem => elem.id === payload.id)
            if (result) {
                result.elements = payload.data
            }
        }
    }
}