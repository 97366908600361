import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Login from '../views/Login.vue'
import Insert from '../views/Insert.vue'
import Home from '../views/Home.vue'
import Documentos from '../views/Documentos.vue'
import Financiamiento from '../views/Financiamiento.vue'
import Proyectos from '../views/Proyectos.vue'
import Corporacion from '../views/Corporacion.vue'
import CollectionLandingPage from '../views/Collection-Landing-Page'
import EditItem from '../views/EditItem.vue'
import System from '../views/System.vue'
import Sorting from '../views/Sorting.vue'
import Deposit from '../views/Deposit.vue'
import Glossary from '../views/Glossary.vue'
import Settings from '../views/Settings.vue'
import sites from '../views/Sites.vue'
import siteviewer from '../views/SiteViewer.vue'
import printBox from '../views/PrintBox.vue'
import DocumentCard from '../views/archive/DocCard.vue'
import ArchiveLandingPage from '../views/archive/Landing-Page.vue'
import Export from '../views/console/Export.vue'
import Statistics from '../views/console/Statistics.vue'
import Mina from '../views/museum/1_mina.vue'
import Planta from '../views/museum/2_planta.vue'
import Laboratorio from '../views/museum/3_laboratorio.vue'
import Hidroelectrica from '../views/museum/4_hidroelectrica.vue'
import Bodegas from '../views/museum/5_bodegas.vue'
import Abastecimiento from '../views/museum/6_abastecimiento.vue'
import Vivienda from '../views/museum/7_vivienda.vue'
import Servicios from '../views/museum/8_servicios.vue'
import VidaCotidiana from '../views/museum/9_cotidiana.vue'
import VidaReligiosa from '../views/museum/10_religiosa.vue'
import PuertoCristal from '../views/museum/puerto-cristal.vue'
import store from '../store/index'
import app from "@/firebase";
import {
  getFirestore,
  query,
  orderBy,
  limit,
  collection
} from "firebase/firestore";
const db = getFirestore(app);

async function checkCurrentItem(to) {
  let elem=await store.dispatch('getAndSetCurrentItem',to.params.id)
  
  if (elem) {
    let ids = elem.keyWords
    store.dispatch('keywords/checkLoadedItems', ids)
  }
  return true
}
async function checkSearchHistory(to) {
  let elem=await store.getters.getSearchHistoryResult(to.params.searchID)
  if (elem) {
    return true
  }else {
    return '/collection'
  }
}
async function isAuthorized(){
  if (store.getters.isUserLoged) {
    store.commit('SET_ADMIN_BAR_DISPLAY', true)
    store.commit('SET_MENU_DISPLAYED', false)
  } else {
    return '/login'
  }
}
async function loadLast5items(){
  await store.dispatch('getItemsByQuery', query(collection(db, 'ListaObjetos'), orderBy('inventory', 'desc'), limit(5)))
}
async function loadFondos(){
  if (store.getters.fondos === undefined || store.getters.fondos.length < 1) {
      await store.dispatch('archive/getFondos')
  }
}
async function loadDocument(to){
  let result=await store.dispatch('archive/loadDocumento', to.params.id)
  if(!result.published){
    isAuthorized()
  }
}
function setCurrentDocument(to){  
  let id=""
  if(to.params.id.length>12){
    let idSplit=to.params.id.split(".")
    id=idSplit[0]+"."+idSplit[1]+"."+idSplit[2]
  }else{
    id=to.params.id
  }
  store.commit('archive/SET_CURRENT_DOC',id)

}
async function loadDepositData(){
  await store.dispatch("getBoxes")
  await store.dispatch("getObjectsInDeposit")
}
async function loadStatistics(){
  store.commit('SET_MISSING_OBJECTS')
  let response=await store.dispatch("getAllObjects")
  console.log(response)
  response=await store.dispatch("materials/getItems")
  console.log(response)
  await store.dispatch("materials/getStatistics")
  console.log(response)
  store.commit('materials/CALC_PERCENTS')
  response=await store.dispatch("techniques/getItems")
  console.log(response)
  response=await store.dispatch("techniques/getStatistics")
  console.log(response)
  store.commit('techniques/CALC_PERCENTS')
  response=await store.dispatch("sitios/getStatistics")
  console.log(response)
  store.commit('sitios/CALC_PERCENTS')
}
const routes = [{
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'system',
    component: System,
    children: [

      {
        path: '/item/:id/:edit/:step',
        name: 'editObjectStep',
        components: {
          system: EditItem
        },
        beforeEnter:[isAuthorized,checkCurrentItem]
      },
      {
        path: '/item/:id/:edit',
        name: 'editObject1',
        components: {
          system: EditItem
        },
        beforeEnter:[isAuthorized,checkCurrentItem]
      },
      {
        path: '/item/:id',
        name: 'editObject',
        components: {
          system: EditItem
        },
        beforeEnter:[checkCurrentItem]

      },
      {
        path: '/printbox/:id',
        name: 'printBox',
        components: {
          system: printBox
        },
        beforeEnter(to, from, next) {
          if (store.getters.isUserLoged) {
            store.commit('SET_ADMIN_BAR_DISPLAY', true)
            store.commit('SET_LOADING', true)
            store.commit('SET_MENU_DISPLAYED', false)
            store.dispatch("getBoxes", to.params.id).then(() => {
              store.dispatch("getObjectsInBox", to.params.id).then(() => {
                store.commit('SET_LOADING', false)
                next()
              })
            })
          } else {
            next('/login')
          }
        }
      },
      {
        path: '/insert',
        name: 'insert',
        components: {
          system: Insert
        },
        beforeEnter:[isAuthorized,loadLast5items]
      },
      {
        path: '/typologies',
        name: 'typologies',
        components: {
          system: Glossary
        },
        beforeEnter:[isAuthorized]
      },

      {
        path: '/sites',
        name: 'sites',
        components: {
          system: sites
        },
        beforeEnter:[isAuthorized]
      },
      {
        path: '/sites/:id',
        name: 'siteviewer',
        components: {
          system: siteviewer
        },
        beforeEnter:[isAuthorized]
      },
      {
        path: '/collection',
        name: 'collection',
        components: {
          system: CollectionLandingPage
        },
      },
      {
        path: '/collection/:searchID',
        name: 'search',
        components: {
          system: CollectionLandingPage
        },
        beforeEnter:[checkSearchHistory]
      },
      {
        path: '/archive',
        name: 'archive',
        components: {
          system: ArchiveLandingPage
        },
        beforeEnter:[loadFondos]
      },
      {
        path: '/archive/:id',
        name: 'documentCard',
        components: {
          system: DocumentCard
        },
        beforeEnter:[loadFondos,loadDocument,setCurrentDocument]
      },
      {
        path: '/',
        name: 'home',
        components: {
          system: Home
        },
        beforeEnter() {
          store.commit('SET_ADMIN_BAR_DISPLAY', false)
          store.commit('SET_MENU_DISPLAYED', false)
          store.commit('SET_LOADING', true)
        }
      },
      {
        path: '/nosotros',
        name: 'nosotros',
        components: {
          system: Corporacion
        },
        beforeEnter() {
          store.commit('SET_ADMIN_BAR_DISPLAY', false)
          store.commit('SET_MENU_DISPLAYED', false)
          store.commit('SET_LOADING', true)
        }
      },      
      {
        path: '/nosotros/corporacion',
        name: 'corporacion',
        components: {
          system: Corporacion
        },
        beforeEnter() {
          store.commit('SET_ADMIN_BAR_DISPLAY', false)
          store.commit('SET_MENU_DISPLAYED', false)
          store.commit('SET_LOADING', true)
        }
      },      
      {
        path: '/nosotros/documentos',
        name: 'documentos',
        components: {
          system: Documentos
        },
        beforeEnter() {
          store.commit('SET_ADMIN_BAR_DISPLAY', false)
          store.commit('SET_MENU_DISPLAYED', false)
          store.commit('SET_LOADING', true)
        }
      },
      {
        path: '/nosotros/proyectos',
        name: 'proyectos',
        components: {
          system: Proyectos
        },
        beforeEnter() {
          store.commit('SET_ADMIN_BAR_DISPLAY', false)
          store.commit('SET_MENU_DISPLAYED', false)
          store.commit('SET_LOADING', true)
        }
      },
      {
        path: '/nosotros/financiamiento',
        name: 'financiamiento',
        components: {
          system: Financiamiento
        },
        beforeEnter() {
          store.commit('SET_ADMIN_BAR_DISPLAY', false)
          store.commit('SET_MENU_DISPLAYED', false)
          store.commit('SET_LOADING', true)
        }
      },
      {
        path: '/deposit',
        name: 'deposit',
        components: {
          system: Deposit
        },
        beforeEnter:[isAuthorized,loadDepositData]
      },
      {
        path: '/categories',
        name: 'categories',
        components: {
          system: Sorting
        },
        beforeEnter:[isAuthorized]
      },
      {
        path: '/settings/:id',
        name: 'settings',
        components: {
          system: Settings
        },
        beforeEnter:[isAuthorized]
      },
      {
        path: '/export',
        name: 'export',
        components: {
          system: Export
        },
        beforeEnter:[isAuthorized]
      },
      {
        path: '/statistics',
        name: 'statistics',
        components: {
          system: Statistics
        },
        beforeEnter:[isAuthorized,loadStatistics]
      },
      {
        path: '/puerto_cristal',
        name: 'puerto_cristal',
        components: {
          system: PuertoCristal
        }
      },
      {
        path: '/puerto_cristal/mina',
        name: 'mina',
        components: {
          system: Mina
        }
      },
      {
        path: '/puerto_cristal/planta_concentrados',
        name: 'planta_concentrados',
        components: {
          system: Planta
        }
      },
      {
        path: '/puerto_cristal/laboratorio',
        name: 'laboratorio',
        components: {
          system: Laboratorio
        }
      },
      {
        path: '/puerto_cristal/taller_electrico',
        name: 'taller_electrico',
        components: {
          system: Hidroelectrica
        }
      },
      {
        path: '/puerto_cristal/bodegas_portuarias',
        name: 'bodegas_portuarias',
        components: {
          system: Bodegas
        }
      },
      {
        path: '/puerto_cristal/abastecimiento',
        name: 'abastecimiento',
        components: {
          system: Abastecimiento
        }
      },
      {
        path: '/puerto_cristal/vivienda',
        name: 'vivienda',
        components: {
          system: Vivienda
        }
      },
      {
        path: '/puerto_cristal/servicios',
        name: 'servicios',
        components: {
          system: Servicios
        }
      },
      {
        path: '/puerto_cristal/vida_cotidiana',
        name: 'vida_cotidiana',
        components: {
          system: VidaCotidiana
        }
      },
      {
        path: '/puerto_cristal/vida_espiritual',
        name: 'vida_religiosa',
        components: {
          system: VidaReligiosa
        }
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(()=>{
  store.commit('SET_LOADING', true)
})
router.afterEach(()=>{
  store.commit('SET_LOADING', false)
})

export default router