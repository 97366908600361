<template>
  <div class="page-container">
    <div class="card-container" v-if="setup.target==='new'">
      <a :href="item.link" target="_blank" class="card color1" v-for="item in setup.list" :key="item.id">
        <div
          class="card-img"
          :style="'background-image:url('+item.imagen+')'"
          v-if="item.imagen"
        ></div>
        <div class="card-img" v-else-if="item.icon!=undefined">
          <i class="material-icons">{{item.icon}}</i>
        </div>
        <div class="card-img" v-else>
          <i class="material-icons">insert_photo</i>
        </div>
        <div class="middle-icon" v-if="!item.content">
          <div class="right">
            <i class="material-icons">add</i>
          </div>
        </div>

        <div class="full-content right" v-else>
          <i class="material-icons">add</i>
          <div class="inner-content">
            <p v-html="item.content"></p>
          </div>
        </div>
        <div class="card-content">
          <h4 v-html="item.title"></h4>
          <p v-if="item.id">{{ item.id }}</p>
        </div>
      </a>
    </div>
      <div class="card-container" v-else-if="setup.target==='self'">
      <router-link class="card color1" v-for="item in setup.list" :key="item.id" :to="item.link">
        <div
          class="card-img"
          :style="'background-image:url('+item.imagen+')'"
          v-if="item.imagen"
        ></div>
        <div class="card-img" v-else-if="item.icon!=undefined">
          <i class="material-icons">{{item.icon}}</i>
        </div>
        <div class="card-img" v-else>
          <i class="material-icons">insert_photo</i>
        </div>
        <div class="middle-icon" v-if="!item.content">
          <div class="right">
            <i class="material-icons">add</i>
          </div>
        </div>

        <div class="full-content right" v-else>
          <i class="material-icons">add</i>
          <div class="inner-content">
            <p v-html="item.content"></p>
          </div>
        </div>
        <div class="card-content">
          <h4 v-html="item.title"></h4>
          <p v-if="item.id">{{ item.id }}</p>
          
        </div>
      </router-link>
    </div>
    <div class="card-container" v-else-if="setup.target==='return'">
      <div class="card color1" v-for="item in setup.list" :key="item.id" @click="$emit('get-result', item.link)">
        <div
          class="card-img"
          :style="'background-image:url('+item.imagen+')'"
          v-if="item.imagen"
        ></div>
        <div class="card-img" v-else-if="item.icon">
          <i class="material-icons">{{item.icon}}</i>
        </div>
        <div class="card-img" v-else>
          <i class="material-icons">insert_photo</i>
        </div>
        <div class="middle-icon" v-if="!item.content">
          <div class="right">
            <i class="material-icons">add</i>
          </div>
        </div>

        <div class="full-content right" v-else>
          <i class="material-icons">add</i>
          <div class="inner-content">
            <p v-html="item.content"></p>
          </div>
        </div>
        <div class="card-content color1">
          <h4 v-html="item.title"></h4>
          <p v-if="item.id">{{ item.id }}</p>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: "Card-Slider",
  props: ["setup"],
  data: function() {
    return {
      itemsPerPage: 5,
      currentPage: 1,
      list:[]
    };
  },
  mounted(){
  },
  watch: {
  },
  computed: {
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
@import "../assets/sass/buttons";
@import "../assets/sass/cards";

.page-container {
  width: 100%;
  padding: 10px 1em;
  height: fit-content !important;
  .show-selector {
    width: 100%;
    position: relative;
    div {
      width: fit-content;
      margin-right: 0px;
      margin-left: auto;
      label {
        width: fit-content;
        margin-right: 5px;
        margin-left: 5px;
      }
      select {
        min-width: 75px;
        width: fit-content;
      }
    }
  }
}
@media (min-width: 768px) {
  .home {
    .section {
      .search-container {
        width: 70%;
      }
    }
    .tabs {
      padding: 15px 10%;
    }
    .section {
      margin: 15px 1em;
      &.side-menu {
        grid-template-columns: auto 260px;
        grid-template-rows: auto;
        grid-template-areas: "objects filter";
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    .section {
      .search-container {
        width: 60%;
      }
    }
    .tabs {
      padding: 15px 15%;
    }
    .section {
      &.side-menu {
        grid-template-columns: auto 350px;
      }
    }
  }
}
</style>
