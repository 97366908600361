<template>
  <div class="bar">
    <button class="menu" @click="setMenu">
      <i class="material-icons">more_vert</i>
    </button>
    <div class="brand">
      <h3>Museo de Puerto Cristal</h3>
    </div>
    <div class="links" v-show="menuDisplayed">
      <div>
        <div v-for="item in links" :key="item.link" class="dropdown">
          <button :class="{ active: $route.path === item.link }">
            <router-link :to="item.link">{{ item.text }}</router-link>
          </button>
          <div class="dropdown-content" v-show="item.subMenu">
            <router-link v-for="obj in item.data" :key="obj.link" :to="obj.link">{{ obj.text }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="rest" v-show="menuDisplayed" @click="hideMenu"></div>
    <div class="profile">
      <button v-if="user === null">
        <router-link :to="'/login'">
          Ingresar
          <i class="material-icons">account_circle</i>
        </router-link>
      </button>
      <button v-else @click="showUser">
        {{ user.name }}
        <i class="material-icons">account_circle</i>
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Nav-bar",

  data: function () {
    return {
      displayMenu: false,
    };
  },
  computed: {
    ...mapGetters(["user", "menuDisplayed"]),
    links() {
      var links = [
        {
          text: "Inicio",
          link: "/",
          subMenu: false,
        },
        {
          text: "Museo",
          link: "/puerto_cristal",
          subMenu: false,
        },
        {
          text: "Colecciones",
          link: "/collection",
          subMenu: false,
        },
        {
          text: "Archivo",
          link: "/archive",
          subMenu: false,
        },
        {
          text: "Nosotros",
          link: "/nosotros",
          subMenu: true,
          data: [
            {
              text: "Corporación",
              link: "/nosotros/corporacion"
            },
            {
              text: "Proyectos",
              link: "/nosotros/proyectos"
            },
            {
              text: "Financiamiento",
              link: "/nosotros/financiamiento"
            },
            {
              text: "Documentos",
              link: "/nosotros/documentos"
            },
          ],
        },
      ];
      if (this.user) {
        links.push({
          text: "Consola",
          link: "/insert",
        });
      }

      return links;
    },
  },
  watch: {
    user() {},
  },
  beforeRouteLeave() {
    this.displayMenu = false;
  },
  methods: {
    ...mapMutations(["SET_PROFILE_DISPLAYED", "SET_MENU_DISPLAYED"]),
    showUser() {
      this.SET_PROFILE_DISPLAYED(true);
    },
    hideMenu() {
      this.SET_MENU_DISPLAYED(false);
    },
    setMenu() {
      this.SET_MENU_DISPLAYED(!this.menuDisplayed);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
div.bar {
  .menu {
    margin-block-end: 0px;
    margin-block-start: 0px;
    margin: 0px;
    padding: 0px;
    border: none;
    background-color: $theme-l1;
    color: antiquewhite;
    cursor: pointer;
    i.material-icons {
      vertical-align: middle;
      font-size: 30px;
      color: antiquewhite;
    }
  }
  .menu:hover {
    background-color: $theme-d1;
  }
  background-color: white;
  padding: 0px;
  display: grid;
  grid-template-columns: 48px auto 127px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  .brand {
    padding-left: 15px;
    h3 {
      line-height: 100%;
    }
  }
  div.profile {
    float: right;
    button {
      border-radius: 0px;
      border: none;
      border-left: solid 2px white;
      background-color: white;

      margin-block-end: 7px;
      margin-block-start: 7px;
      color: $text;
      height: auto;
      padding: 7px 15px;
      float: right;
      a {
        text-decoration: none;
      }
    }
    button:hover,
    button.active {
      border-left-color: $theme;
      color: $theme;
    }
  }
  .rest {
    width: calc(100vh - 320px);
    position: absolute;
    z-index: 3;
    height: 100vh;
    top: 0%;
    margin-top: 0px;
    left: 0;
    margin-left: 320px;
  }
  div.links {
    width: auto;
    position: absolute;
    z-index: 3;
    float: right;
    height: calc(100vh - 50px);
    top: 0%;
    margin-top: 50px;
    background-color: white;
    div {
      width: 100%;

      div.dropdown {
        position: relative;
        display: inline-block;
        button {
          border-radius: 0px;
          border: none;
          border-left: solid 2px white;
          background-color: white;
          width: 100%;
          margin-block-end: 4px;
          margin-block-start: 4px;
          color: $text;
          height: auto;
          padding: 7px 15px;
          text-align: left;
          a {
            text-decoration: none;
          }
        }

        button:hover,
        button.active {
          border-left-color: $theme;
          color: $theme;
        }
      }
      /* Dropdown Content (Hidden by Default) */
      .dropdown-content {
        display: none;
        position: absolute;
        margin-top: auto;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
      }

      /* Links inside the dropdown */
      .dropdown-content a {
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }

      /* Change color of dropdown links on hover */
      .dropdown-content a:hover {
        background-color: $theme-d1;
        color: white;
      }

      /* Show the dropdown menu on hover */
      .dropdown:hover .dropdown-content {
        display: block;
      }
      .dropdown:hover button {
          border-left-color: $theme;
          color: $theme;
        }
    }
  }
  div.links.visible {
    display: block;
  }
  div.profile {
    margin-right: 8px;
    border-left: solid 1px $text;

    margin-block-end: 10px;
    margin-block-start: 10px;
    button {
      border-left: none;
      padding: 0px 10px;
      margin-block-end: 0px;
      margin-block-start: 0px;
      i.material-icons {
        font-size: 30px;
        vertical-align: middle;
      }
    }
  }
}
@media (min-width: 795px) {
  div.bar {
    width: 100%;
    .menu {
      display: none;
    }
    grid-template-columns: auto auto 127px;
    .links {
      display: inline-block !important;
      width: 100% !important;
      position: relative !important;
      z-index: auto !important;
      height: 100% !important;
      top: 0%;
      margin-top: 0px !important;
      div {
        width: fit-content !important;
        margin-top: 4px;
        div.dropdown {
          float: left;
          button {
            border-radius: 0px;
            border: none;
            border-left: solid 2px white;
            background-color: white;
            width: fit-content !important;
            margin-block-end: 7px;
            margin-block-start: 7px;
            color: $text;
            height: auto;
            padding: 7px;
            float: left;
            a {
              text-decoration: none;
            }
          }
          button:hover,
          button.active {
            border-left-color: $theme;
            color: $theme;
          }
        }
        .dropdown-content {
          margin-top: 35px !important;
        }
      }
    }
  }
}
a.router-link-exact-active {
  color: $theme;
}
</style>